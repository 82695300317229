import { Box } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";
import { useOfferingSettings } from "../../../../hooks/useOfferings";
import { useMemo } from "react";

const ManualPaymentInstructions = ({ investment }) => {
  const { data: settings } = useOfferingSettings(investment?.offering?.id);

  const settingsMap = useMemo(() => {
    const settingValue = (key) =>
      settings?.find((s) => s.id === key)?.value ?? "";

    return {
      wire: settingValue("manual_payment_wire_instructions"),
      check: settingValue("manual_payment_check_instructions"),
    };
  }, [settings]);

  const latestPayment = useMemo(() => {
    return investment?.payments?.at(0);
  }, [investment.payments]);

  return (
    <Box
      w="full"
      py={2}
      borderBottom="1px"
      borderColor="gray.200"
      _last={{ border: "unset", pb: 0 }}
    >
      <Heading fontWeight="bold">
        {latestPayment?.payment_type === "wire"
          ? "Please initiate your wire to the account listed below:"
          : "Include the investment amount and send your check to:"}
      </Heading>
      <Text textStyle="body" m={0}>
        {settingsMap?.[latestPayment?.payment_type]?.length > 0 && (
          <Box
            dangerouslySetInnerHTML={{
              __html: settingsMap?.[latestPayment?.payment_type]?.replaceAll(
                "\n",
                "<br />"
              ),
            }}
          />
        )}
      </Text>
    </Box>
  );
};

export default ManualPaymentInstructions;
