import { Button } from "@chakra-ui/react";

const WorkflowForm = ({ onSubmit, children }) => {
  return (
    <form onSubmit={onSubmit}>
      {children}
      {/* hidden button triggers form submit when pressing enter  */}
      <Button type="submit" hidden />
    </form>
  );
};

export default WorkflowForm;
