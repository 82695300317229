import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import AgreementClient from "../clients/AgreementClient";
import { useApi } from "./useApi";

export const useOfferingAgreements = (offeringId, options = {}) => {
  const agreementApi = useApi(AgreementClient);

  return useQuery(
    ["offering", offeringId, "offering_agreements"],
    () => agreementApi.offeringAgreements(offeringId),
    {
      enabled: !!offeringId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );
};

export const useDeleteOfferingAgreement = (
  id: string,
  options: Omit<
    UseMutationOptions<unknown, unknown, unknown, unknown>,
    "mutationFn"
  > = {}
) => {
  const agreementApi = useApi(AgreementClient);

  return useMutation(
    async (agreementId: string) =>
      agreementApi.deleteOfferingAgreement(id, agreementId),
    options
  );
};

export const useCreateOfferingAgreement = (
  id: string,
  options: Omit<
    UseMutationOptions<unknown, unknown, unknown, unknown>,
    "mutationFn"
  > = {}
) => {
  const agreementApi = useApi(AgreementClient);

  return useMutation(
    async (data: Record<string, unknown>) =>
      agreementApi.createOfferingAgreement(id, data),
    options
  );
};

export const useInvestmentAgreementSupport = (
  investmentAgreementId: string,
  options = {}
) => {
  const agreementApi = useApi(AgreementClient);

  return useQuery(
    ["investment", investmentAgreementId, "agreement-support"],
    () => agreementApi.agreementSupport(investmentAgreementId),
    {
      enabled: !!investmentAgreementId,
      ...options,
    }
  );
};

export const useInvestmentAgreementSignatureRepair = (
  investmentAgreementId: string,
  options = {}
) => {
  const agreementApi = useApi(AgreementClient);

  return useMutation(
    ({ email }: { email: string }) =>
      agreementApi.agreementSignatureRepair(investmentAgreementId, email),
    {
      ...options,
    }
  );
};

export const useUpdateOfferingAgreement = (
  id: string,
  offeringAgreementId: string,
  options = {}
) => {
  const agreementAPi = useApi(AgreementClient);

  return useMutation(
    async (data: Record<string, unknown>) =>
      agreementAPi.updateOfferingAgreement(id, offeringAgreementId, data),
    { ...options }
  );
};

export const useAgreementPingNextSigner = (investmentId) => {
  const agreementApi = useApi(AgreementClient);

  return useMutation({
    mutationFn: () => agreementApi.pingNextSecondarySigner(investmentId),
  });
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestmentSignatures = (id, options = {}) => {
  const agreementApi = useApi(AgreementClient);

  return useQuery(
    ["investment", id, "signatures"],
    () => agreementApi.investmentSignatures(id),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...options,
    }
  );
};
