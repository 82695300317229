import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import type {
  AsyncProviderConfig,
  LDContext,
} from "launchdarkly-react-client-sdk/lib";

type EQUser = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
};
type EQLaunchDarklyOptionsT = Omit<AsyncProviderConfig, "clientSideID">;

export function launchDarklyUser(user: EQUser): LDContext {
  return {
    kind: "user",
    id: user.id,
    email: user.email,
    key: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    name: `${user.first_name} ${user.last_name}`,
    _meta: {},
  };
}

const DEFAULT_LD_CONFIG: EQLaunchDarklyOptionsT = {
  options: { bootstrap: "localStorage" },
};

export const buildLDProvider = async (
  clientSideID: string,
  options: EQLaunchDarklyOptionsT = {}
) => {
  return asyncWithLDProvider({
    clientSideID,
    ...DEFAULT_LD_CONFIG,
    ...options,
  });
};
