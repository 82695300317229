import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, useToast } from "@chakra-ui/react";
import { BackButton, Heading, Input, Text } from "@equidefi/ui";

import UserToken from "@equidefi/portals/clients/UserToken";
import { ResendMfaLink } from "@equidefi/portals/components/ResendMfaLink";
import {
  useMFACode,
  useLogin,
} from "@equidefi/portals/hooks/useAuthentication";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LoginContainer from "./LoginContainer";

const MFA = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const authFunction = useLogin();
  const location = useLocation();

  useDocumentTitle("Multi-Factor Authentication");

  const email = useSelector((state) => state.email);
  const [mfaCode, setMfaCode] = useState("");
  const [spinner, showSpinner] = useState(false);

  const toast = useToast();
  const confirmMFA = useMFACode({
    onSettled: () => showSpinner(false),
    onSuccess: (data) => {
      dispatch({ type: "token", payload: data.token });
      dispatch({ type: "user", payload: data.user });

      UserToken.setToken(data.token);
      currentUser.refetch();

      const redirectURL = location?.state?.redirectURL ?? "/vault/dashboard";
      navigate(redirectURL);
    },
    onError: () => {
      toast({
        status: "error",
        description: "Incorrect code, please try again",
      });
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    showSpinner(true);

    await confirmMFA.mutateAsync({
      email: email,
      mfa_code: mfaCode,
    });
  };

  return (
    <LoginContainer>
      <form onSubmit={handleLogin} style={{ width: "100%" }}>
        <Heading color="white" fontWeight="bold" m="0" textStyle="h3">
          Two Factor Authentication
        </Heading>
        <Text color="white" mb="5" textStyle="body2">
          Your account is protected with two-factor authentication. We've sent
          you an email. Please enter the code below.
        </Text>
        <ResendMfaLink
          authFunction={authFunction}
          color="white"
          state={location.state}
        />
        <Input
          as={NumberFormat}
          name="mfa_code"
          format="######"
          mask="_"
          placeholder="0 0 0 0 0 0"
          value={mfaCode}
          onValueChange={({ value }) => setMfaCode(value)}
          h="12"
          textAlign="center"
          mb="5"
          mt="2"
        />

        <BackButton
          color="white"
          textAlign="center"
          text="Back to login"
          onClick={() => navigate("/")}
        />

        <Button
          isDisabled={mfaCode.length !== 6}
          h="12"
          w="full"
          type="submit"
          isLoading={spinner}
        >
          Continue
        </Button>
      </form>
    </LoginContainer>
  );
};

export default MFA;
