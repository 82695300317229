import { ApiClient } from "./ApiClient";

class GoogleClient extends ApiClient {
  async autocomplete(query) {
    const response = await this.get(`/v2/google/autocomplete`, {
      params: { search: query },
    });
    return response.data;
  }

  async place(id) {
    const response = await this.get(`/v2/google/place/${id}`);
    return response.data;
  }
}

export default GoogleClient;
