import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { offeringIsActive } from "@equidefi/shared";

import OfferingError from "../../../components/offerings/OfferingError";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContext } from "../context";
import Register from "./Register";

const WorkflowRegistration = (props) => {
  const { isLoggedIn, offering } = useContext(WorkflowContext);
  useWorkflowPosition(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/offerings/${offering.slug}/choose`);
    }
  }, [isLoggedIn, navigate, offering?.slug]);

  if (offering?.status && !offeringIsActive(offering)) {
    return (
      <OfferingError
        title="Offering Not Available"
        message={`Sorry but you cannot invest in this offering, yet.`}
      />
    );
  }

  return <Register offering={offering} history={props.history} />;
};

export default WorkflowRegistration;
