import React, { useState, useEffect } from "react";
import { VStack, useToast, HStack } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";

const SEND_MFA_CODE_COUNTDOWN_SECONDS = 30;

export const ResendMfaLink = ({
  authFunction = {},
  color = "black",
  alignSelf = "start",
  hasCountdown = true,
  state,
}) => {
  const [countdown, setCountdown] = useState(0);
  const toast = useToast();

  const onResend = async () => {
    if (countdown > 0) return;

    try {
      await authFunction.mutateAsync({
        email: state.email,
        password: state.password,
      });

      if (hasCountdown) {
        // set countdown to 30 seconds to prevent spamming
        setCountdown(SEND_MFA_CODE_COUNTDOWN_SECONDS);
      }

      toast({
        status: "info",
        description: `New code sent to ${state.email}`,
      });
    } catch (error) {
      toast({
        status: "error",
        description: "There was an error trying to resend 2FA code",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <VStack alignSelf={alignSelf} color={color}>
      <Heading fontWeight="bold" m="0" textStyle="body2" alignSelf="start">
        Didn't get a code?
      </Heading>
      <HStack spacing={2} alignSelf={alignSelf}>
        <Text
          color={countdown > 0 ? "gray.500" : "blue.600"}
          textStyle="body2"
          cursor={countdown > 0 ? "default" : "pointer"}
          onClick={countdown > 0 ? undefined : onResend}
          textDecor={countdown > 0 ? "none" : "underline"}
          _hover={{ color: countdown > 0 ? "gray.500" : "blue.800" }}
          mb="0"
        >
          Resend Code
        </Text>
        {countdown > 0 && (
          <Text color="gray.500" textStyle="body2" mb="0">
            ({countdown}s)
          </Text>
        )}
      </HStack>
    </VStack>
  );
};
