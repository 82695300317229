export const QUOTES = [
  {
    quote:
      "Nobody buys a farm based on whether they think it's going to rain next year.",
    name: "Warren Buffett",
  },
  {
    quote: "Time in the market, beats timing the market.",
    name: "Ken Fisher",
  },
  {
    quote:
      "How many millionaires do you know have become wealthy by investing in a savings accounts?",
    name: "Robert G. Allen",
  },
  {
    quote: "The biggest risk of all, is not taking one.",
    name: "Mellody Hobson",
  },
  {
    quote: "Don't look for the needle in the haystack. Just buy the haystack!",
    name: "John Bogle",
  },
  {
    quote:
      "I don't look to jump over seven-foot bars; I look around for one-foot bars that I can step over.",
    name: "Warren Buffett",
  },
];
