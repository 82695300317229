import { Font, StyleSheet } from "@react-pdf/renderer";

import CerebriSansExtraBold from "../fonts/cerebrisans/cerebrisans-extrabold.ttf";
import CerebriSansExtraBoldItalic from "../fonts/cerebrisans/cerebrisans-extrabolditalic.ttf";
import CerebriSansItalic from "../fonts/cerebrisans/cerebrisans-italic.ttf";
import CerebriSansMedium from "../fonts/cerebrisans/cerebrisans-medium.ttf";
import CerebriSansMediumItalic from "../fonts/cerebrisans/cerebrisans-mediumitalic.ttf";
import CerebriSansRegular from "../fonts/cerebrisans/cerebrisans-regular.ttf";

Font.register({ family: "Cerebri Sans", src: CerebriSansRegular });
Font.register({
  family: "Cerebri Sans",
  src: CerebriSansMedium,
  fontWeight: "medium",
});
Font.register({
  family: "Cerebri Sans",
  src: CerebriSansMediumItalic,
  fontWeight: "medium",
  fontStyle: "italic",
});
Font.register({
  family: "Cerebri Sans",
  src: CerebriSansExtraBold,
  fontWeight: "heavy",
});
Font.register({
  family: "Cerebri Sans",
  src: CerebriSansExtraBoldItalic,
  fontWeight: "heavy",
  fontStyle: "italic",
});
Font.register({
  family: "Cerebri Sans",
  src: CerebriSansItalic,
  fontStyle: "italic",
});

export const styles = StyleSheet.create({
  document: {
    flex: 1,
    width: "100%",
    height: "500px",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    alignContent: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    padding: "25px",
  },
  centered: {
    textAlign: "center",
  },
  bold: { fontWeight: "heavy" },
  semibold: { fontWeight: "medium" },
  underline: { textDecoration: "underline" },
  italic: { fontStyle: "italic" },
  section: {
    fontSize: 12,
    margin: 0,
    marginBottom: 20,
    padding: 0,
    width: "100%",
  },
  sectionItem: {
    fontSize: 12,
    lineHeight: 1.3,
  },
  sectionTitle: {
    fontWeight: "heavy",
    textDecoration: "underline",
    fontStyle: "italic",
    marginBottom: 4,
  },
  paragraph: {
    marginBottom: 10,
  },
  table: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#808080",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#E0E0E0",
  },
  tableCell: {
    border: "solid #808080",
    borderWidth: 1,
    textAlign: "left",
    fontSize: 12,
    padding: 5,
    wordBreak: "break-word",
    flexWrap: "wrap",
  },
  title: {
    textAlign: "center",
    fontFamily: "Cerebri Sans",
    fontSize: 20,
    fontWeight: "medium",
    textAlign: "left"
  },
  subtitle: {
    fontFamily: "Cerebri Sans",
    fontSize: 9,
  },
  image: {
    width: 132,
    height: 42,
    margin: 0,
    padding: 0,
  },
  logo: {
    width: "100%",
    marginBottom: 20,
    padding: 0,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  invoice: {
    fontSize: 24,
    fontFamily: "Cerebri Sans",
    fontWeight: "heavy",
    margin: 0,
    padding: 0,
  },
  issuer: {
    // backgroundColor: 'blue',
    width: "40%",
  },
  due: {
    // backgroundColor: 'red',
    width: "40%",
    alignItems: "flex-end",
  },
  dueLabel: {
    fontFamily: "Cerebri Sans",
    fontSize: 10,
    fontWeight: "medium",
  },
  dueDate: {
    fontFamily: "Cerebri Sans",
    fontSize: 12,
  },
  name: {
    fontSize: 14,
    fontFamily: "Cerebri Sans",
    fontWeight: "medium",
  },
  address: {
    fontSize: 11,
    fontFamily: "Cerebri Sans",
  },
  phone: {
    fontSize: 11,
    fontFamily: "Cerebri Sans",
  },
  billable: {
    fontFamily: "Cerebri Sans",
    fontSize: 10,
    fontWeight: "medium",
  },
  row: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    // backgroundColor: 'green'
  },
  bill: {
    width: "100%",
    marginTop: 50,
    padding: 20,
    // backgroundColor: 'blue'
  },
  total: {
    width: "100%",
    height: 100,
    marginTop: "0",
    // backgroundColor: 'red'
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  table: {
    root: {
      // borderCollapse: "collapse",
      border: "1px solid #333333",
      padding: "7px",
      width: "100%",
    },
    header: {
      padding: "5px",
      fontFamily: "Cerebri Sans",
      fontWeight: "medium",
      fontSize: 11,
    },
    cell: {
      padding: "5px",
      fontFamily: "Cerebri Sans",
      fontSize: 9,
    },
    cellNoBorder: {
      border: 0,
      padding: "6px",
    },
    total: {
      border: 0,
      padding: "6px",
      fontFamily: "Cerebri Sans",
      fontSize: 10,
      fontWeight: "medium",
    },
  },
});
