import { Box, Button, useToast } from "@chakra-ui/react";
import React from "react";

import { useAgreementPingNextSigner } from "../../hooks/useAgreements";
import { Icon } from "@equidefi/ui/icon";

const SendReminderButton = ({ investmentId, isVisible = false }) => {
  const pingSigner = useAgreementPingNextSigner(investmentId);
  const toast = useToast();

  const handlePingSigner = async () => {
    try {
      await pingSigner.mutateAsync();
      toast({
        status: "success",
        description: "The next signer has received a reminder to sign",
      });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: "Something went wrong pinging the next signer",
      });
    }
  };

  if (!isVisible) {
    return false;
  }

  return (
    <Button
      size="sm"
      onClick={handlePingSigner}
      isLoading={pingSigner.isLoading}
      color="equidefi.blue"
      variant="none"
      display="flex"
      alignItems="center"
      justifyContent={{ base: "start", md: "end" }}
      gap={1}
      ml="0"
      p="0"
      alignSelf={{ base: "start", md: "center" }}
      flexFlow="row nowrap"
      textAlign={{ base: "left", md: "right" }}
      minW="25%"
      h="full"
    >
      <span>Send Reminder</span>
      <Box as="span" flexShrink="0">
        <Icon.Bell size="1em" />
      </Box>
    </Button>
  );
};

export default SendReminderButton;
