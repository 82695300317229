import { Box, Flex, IconButton } from "@chakra-ui/react";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { Link } from "react-router-dom";

const OfferingInvestorPackageList = ({ documents = [] }) => {
  return (
    <>
      {documents?.map((document, index) => (
        <Box
          w="full"
          py={2}
          borderBottom="1px"
          borderColor="gray.200"
          _last={{ border: "unset", pb: 0 }}
          key={index}
        >
          <Flex align="space-beetwen" w="full">
            <Text maxW="60%" m="0" mr="auto">
              {document.type}
            </Text>
            <IconButton
              as={Link}
              color="equidefi.blue"
              bg="none"
              icon={<Icon.Download size="1.5em" />}
              to={document.document_url}
              rel="external noreferrer noopener"
              target="_blank"
            />
          </Flex>
        </Box>
      ))}
    </>
  );
};

export default OfferingInvestorPackageList;
