import { Box, Button } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { Icon } from "@equidefi/ui/icon";

import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useOfferingSettings } from "../../../hooks/useOfferings";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContext } from "../context";
import styles from "./payment.module.css";

export const WorkflowPaymentManual = () => {
  const { offering } = useContext(WorkflowContext);
  useWorkflowPosition(3);
  const { data: settings } = useOfferingSettings(offering.id);

  const settingsMap = useMemo(() => {
    const settingValue = (key) =>
      settings?.find((s) => s.id === key)?.value ?? "";

    return {
      bank_name: settingValue("payment_bank_name"),
      account_name: settingValue("payment_account_name"),
      account_number: settingValue("payment_account_number"),
      routing_number: settingValue("payment_routing_number"),
      transfer_agent_name: settingValue("transfer_agent_company"),
      wireInstructions: settingValue("manual_payment_wire_instructions"),
      checkInstructions: settingValue("manual_payment_check_instructions"),
    };
  }, [settings]);

  useDocumentTitle(["Manual Payment", offering?.name, offering?.issuer?.name]);

  return (
    <div className={styles.payment}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <h1 className="mt-6 display-4 text-center text-md-start d-print-none">
              Thank You!
            </h1>
            <p className="lead text-muted d-print-none">
              Please initiate your wire to the account listed below:
            </p>
            <div className="card bg-eq-cardbg">
              <div className="card-body p-4 p-md-5">
                <div className="row">
                  <div className="col col-12">
                    {settingsMap.wireInstructions.length > 0 && (
                      <>
                        <p>
                          <strong>
                            <em>
                              If you wish to pay by bank wire transfer, please
                              use the wire instructions below:
                            </em>
                          </strong>
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: settingsMap.wireInstructions?.replaceAll(
                              "\n",
                              "<br />"
                            ),
                          }}
                        ></p>
                      </>
                    )}

                    {settingsMap.checkInstructions.length > 0 && (
                      <>
                        <p>
                          <strong>
                            <em>
                              If you would like to send in a check for your
                              investment, please follow the information below:
                            </em>
                          </strong>
                        </p>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: settingsMap.checkInstructions?.replaceAll(
                              "\n",
                              "<br />"
                            ),
                          }}
                        ></p>
                      </>
                    )}

                    {/* <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">Bank Name:</h4>
                      <span> {bankData?.bank_name}</span>
                    </span>
                    <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">Account Name:</h4>
                      <span> {bankData?.account_name} </span>
                    </span>
                    <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">Account Number:</h4>
                      <span> {bankData?.account_number}</span>
                    </span>
                    <span className="d-block">
                      <h4 className="fw-bold d-inline pt-4">ABA Routing No:</h4>
                      <span> {bankData?.routing_number}</span>
                    </span>
                    {bankData?.transfer_agent_name && (
                      <span className="d-block">
                        <h4 className="fw-bold d-inline pt-4">Reference:</h4>
                        <span>
                          {" "}
                          {bankData?.transfer_agent_name}, as escrow agent for{" "}
                          {offering?.issuer?.name}
                        </span>
                      </span>
                    )} */}
                  </div>

                  <Box className="col mt-3 mt-md-0 text-md-end">
                    <span
                      style={{ borderTop: ".1px solid rgb(37, 105, 195,0.3)" }}
                      className="d-flex flex-row w-100 justify-content-center align-content-center gap-2 align-items-center pt-3"
                    >
                      <span className="text-center d-print-none">
                        Would you like to print a copy of it?
                      </span>
                      <Button
                        size="sm"
                        onClick={() => window.print()}
                        leftIcon={<Icon.Printer size="1em" />}
                      >
                        Print
                      </Button>
                    </span>
                  </Box>
                </div>
              </div>
            </div>
            <hr />
            <p className="d-print-none">
              Your EquiDeFi Investor Account has been created. With this unique
              account you can access all of your private placement
              participations in one convenient and secure location. Whether you
              plan to deposit your shares into your brokerage account or convert
              them into shares of a public company, we have you covered. Through
              a system of smart notifications and important anniversaries and
              events, we will assist you with the necessary legal requirements
              and will always be available to answer questions in connection
              with your investment Subscription Plan.{" "}
            </p>

            <Link
              to={`/offerings/${offering.slug}/address`}
              className="btn btn-eq-primary"
            >
              Continue <i className="fe fe-arrow-right ms-1"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
