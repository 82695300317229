import { Flex } from "@chakra-ui/react";
import { Icon } from "./icon";
import { Text } from "./Text";

interface BackButtonProps {
  text: string;
  onClick: () => void;
  color: string;
}

export const BackButton = ({
  text,
  onClick,
  color = "black",
}: BackButtonProps) => {
  return (
    <Flex
      onClick={onClick}
      color={color}
      mb="5"
      align="center"
      cursor="pointer"
    >
      <Icon.ArrowLeft size="1.2em" />
      <Text mb="0" ml="2.5">
        {text}
      </Text>
    </Flex>
  );
};
