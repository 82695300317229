import { useFormik } from "formik";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Flex, useToast } from "@chakra-ui/react";
import { useUpdateVault } from "@equidefi/portals/hooks/useAccreditation";
import
  {
    ACCREDITATION_OPTIONS,
    PRO_LETTER,
  } from "@equidefi/shared/constants/accreditation";
import { Heading, Text } from "@equidefi/ui";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";

import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import { WorkflowContext } from "../context";
import { UploadComponent } from "./UploadComponent";

const helperText = {
  PRO_LETTER: {
    text: "Recommended. Pro letter is a CPA, attorney, registered investment advisor, or broker-dealer letter on their letterhead, attesting to your status as an accredited investor based upon their knowledge of your income or your net worth. This letter must be dated within the prior 60 days.",
    header: "Accredited Investor Verification Letter",
  },
  INCOME_VERIFICATION: {
    text: "Income verification documents (such as IRS Forms 1040 or W-2) for us to review that evidence of your income for the prior two years.",
    header: "Income Verification",
  },
  ASSET_VERIFICATION: {
    text: "Asset verification documents (such as bank or brokerage statements dated within the past 60 days), which we will review. If you use this method we will request a credit report from a national credit reporting agency to verify your debt, obtain a letter from you verifying that no debt exists other than what appears on the credit report, and calculate your estimated net worth to assist us in making a determination.",
    header: "Asset Verification",
  },
};

export const WorkflowAccreditation = () => {
  const { investment, analytics } = useContext(WorkflowContext);
  const navigate = useNavigate();

  const updateVault = useUpdateVault(investment?.id);

  useWorkflowPosition(8);
  const toast = useToast();

  const onSubmit = async () => {
    const validValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== null)
    );

    try {
      await updateVault.mutateAsync(validValues);

      try {
        analytics.track("complete");
        navigate(`/vault/dashboard`, {
          state: {
            completed: true,
            isPaid:
              investment?.payments?.filter((p) => p.status === "succeeded")
                ?.length > 0,
            investment_id: investment.id,
          },
        });
      } catch (error) {
        console.log(error);
        toast({
          status: "error",
          description: "Failed to update your investment",
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        status: "error",
        description: "Failed to submit your files",
      });
    }
  };

  const { handleSubmit, values, handleChange, setFieldValue, touched, errors } =
    useFormik({
      initialValues: {
        accreditation_method: PRO_LETTER,
      },
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit,
    });

  return (
    <WorkflowContainer
      title="Accreditation Documents"
      onContinue={onSubmit}
      buttonLabel="Finish Investment"
      buttonProps={{
        isDisabled: !values.doc_1_file || !values.utility_bill,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Text>
          You must upload documents that verify your status as an accredited
          investor.
        </Text>

        <Heading fontWeight="bold" mb="0">
          Verification Method
        </Heading>
        <Text>Select a verification method below:</Text>

        <RadioGroup
          fieldName="accreditation_method"
          options={ACCREDITATION_OPTIONS}
          errors={touched.accreditation_method && errors}
          setFieldValue={setFieldValue}
          defaultValue={values.accreditation_method}
        />
        <Alert
          status="info"
          flexDirection="column"
          borderRadius="8"
          border="1px solid"
          borderColor="equidefi.blue"
          bg="#D7DEF8"
          mb="6"
          alignItems="start"
          padding="4"
        >
          <Heading fontWeight="bold" m="0" textStyle="h3">
            {helperText[values.accreditation_method].header}
          </Heading>
          <Text m="0">{helperText[values.accreditation_method].text}</Text>
        </Alert>

        <UploadComponent
          title="Your Verification Documents"
          investmentId={investment.id}
          id={`documents_upload`}
          handleChange={handleChange}
          vaultFields={[
            "doc_1_file",
            "doc_2_file",
            "doc_3_file",
            "doc_4_file",
            "doc_5_file",
          ]}
          mb={0}
        />

        <Flex align="left" gap="1" mt="6" direction="column">
          <Heading fontWeight="bold" m="0">
            Proof Of Address
          </Heading>
          <Text>
            You must also upload a proof of address document, such as utility
            bill.
          </Text>
        </Flex>

        <UploadComponent
          title="You Proof Of Address Documents"
          investmentId={investment?.id}
          id="document_upload_utility"
          handleChange={handleChange}
          vaultFields={["utility_bill"]}
          showAlert={true}
          mb={4}
        />
      </form>
    </WorkflowContainer>
  );
};
