import { Button } from "@chakra-ui/react";

export const WorkflowButton = ({ children, ...rest }) => {
  return (
    <Button
      w="full"
      size="md"
      borderRadius="5px"
      py={2}
      px={4}
      bg="primary"
      transition="0.2s"
      _hover={{
        filter: "brightness(120%)",
      }}
      _active={{
        filter: "brightness(160%)",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
