import { Alert, AlertIcon, HStack } from "@chakra-ui/react";
import { Heading, Paper, Text } from "@equidefi/ui";

export const InvestmentRejected = () => {
  return (
    <Paper mt="4">
      <Alert
        status="error"
        w="full"
        p="4"
        borderRadius="8px"
        border="2px solid"
        borderColor="equidefi.red"
        flexDirection="column"
        alignItems="flex-start"
      >
        <HStack mb="2.5">
          <AlertIcon ml="0" />
          <Heading m="0" fontWeight="bold">
            Your Investment Was Rejected
          </Heading>
        </HStack>

        <Text textStyle="body2" m={0}>
          We're sorry, but your investment was rejected. Your payment will be
          refunded in accordance with our terms and conditions.
        </Text>
      </Alert>
    </Paper>
  );
};
