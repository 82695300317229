import { useMemo, useState } from "react";

import { investmentContinueUrl } from "../helpers/investment";
import { useInvestment } from "./useInvestments";

const currentOfferingInvestmentKey = (offeringId) =>
  `offering/${offeringId}/investmentId`;

export const useInvestmentJumpToStep = (offeringId) => {
  const [investmentId, setInvestmentIdState] = useState(() => {
    return localStorage.getItem(currentOfferingInvestmentKey(offeringId));
  });

  const setInvestmentId = (id) => {
    localStorage.setItem(currentOfferingInvestmentKey(offeringId), id);
    setInvestmentIdState(id);
  };
  const { data: investment } = useInvestment(investmentId);

  const url = useMemo(() => {
    return investmentContinueUrl(investment);
  }, [investment?.id, offeringId]);

  return [url, setInvestmentId];
};
