import { Center, Image, VStack } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";
import EquidefiLogo from "../images/equidefi-logo-black.png";

const MaintenanceScreen = () => {
  return (
    <Center
      flexDirection="column"
      minW="100vw"
      minH="100vh"
      bg="rgba(114, 131, 160, 0.2)"
    >
      <VStack
        justify="center"
        maxW="500px"
        px="10"
        gap="2.5"
        textAlign="center"
      >
        <Heading textStyle="h1" fontWeight="bold" mb="0" mt="150">
          Scheduled Maintenance
        </Heading>
        <Text textStyle="body" mb="150">
          Pardon the interruption! We’re working on making your experience
          better. We’ll be back shortly!
        </Text>
        <Image
          src={EquidefiLogo}
          alt="EquiDeFi"
          maxW="176px"
          alignSelf="center"
          opacity="30%"
        />
      </VStack>
    </Center>
  );
};

export default MaintenanceScreen;
