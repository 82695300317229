import { createContext, useState } from "react";

export const PaymentStepContext = createContext({
  clientSecret: null,
  isStripeReady: false,
  onStripeChange: () => {},
});

export const usePaymentStep = () => {
  const [complete, setComplete] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);

  /**
   * @param {import("@stripe/stripe-js").StripePaymentElementChangeEvent} data
   */
  const handleOnChange = (data) => {
    setComplete(data.complete);
  };

  return {
    complete,
    isStripeComplete: complete,
    clientSecret,
    setClientSecret,
    onChange: handleOnChange,
  };
};
