import { Stack, useToast } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import NavBar from "../../../components/nav/NavBar";

const VaultLayout = () => {
  const { data: user, isFetching } = useCurrentUser();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!isFetching && !user?.id) {
      toast({
        description: "Please log in to access your vault",
        isClosable: true,
      });
      navigate("/");
    }
  }, [user, isFetching, navigate, toast]);

  return (
    <Stack
      as="main"
      bgColor="background"
      direction={{ base: "column", lg: "row" }}
      minH={"100vh"}
      h="full"
    >
      <NavBar>
        <Outlet />
      </NavBar>
    </Stack>
  );
};

export default VaultLayout;
