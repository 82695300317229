import React from "react";

function InvestmentNdaCopy({ name, state }) {
  return (
    <ol>
      <li>
        I have requested and agree to receive certain information regarding{" "}
        {name} a <span className="text-capitalize">{state}</span> corporation
        (the “Company”). Information I receive whether written or oral or
        whether prepared by the Company or otherwise (the “Information”),may be
        deemed to be material non-public information. As a condition to my being
        furnished the Information, I agree to treat the Information as
        confidential and to take necessary precautions to safeguard and protect
        the confidentiality of the Information.
      </li>
      <li>
        I hereby agree that the Information will not be used for any purpose
        other than in connection with my evaluating the Company, and that I will
        not disclose in any manner whatsoever such Information, the fact that I
        have received such Information or that discussions or negotiations are
        taking place concerning my interest in the Company or any transaction it
        may propose
      </li>
      <li>
        I am aware that the United States securities laws prohibit any person
        who has received material, nonpublic information concerning a company
        from purchasing or selling securities of such company, or from
        communicating such information to any other person under circumstances
        in which it is reasonably foreseeable that such person is likely to
        purchase or sell such securities. I represent and warrant that I am an
        “accredited investor” as defined in Rule 501(a) under the Securities Act
        of 1933, as amended.{" "}
      </li>
      <li>
        Upon the Company&apos;s request, I shall promptly redeliver to the
        Company or destroy all written Information (whether prepared by the
        Company or otherwise), and any oral information provided to me will
        continue to be subject to the terms of this agreement.
      </li>
      <li>
        The Company shall be entitled to specific performance or other equitable
        relief, including injunction, in the event of any breach or threatened
        breach of the provisions of this agreement and that I shall not oppose
        the granting of such relief. Such remedy shall not be deemed to be the
        exclusive remedy for a breach of this agreement but shall be in addition
        to all other remedies at law or in equity.
      </li>
      <li>
        Unless and until a definitive agreement with the Company has been
        executed and delivered, neither the Company nor I will be under any
        legal obligation of any kind whatsoever with respect to such a
        transaction by virtue of this agreement, except for the matters
        specifically agreed to herein, the Company a third-party beneficiary of
        this agreement.
      </li>
      <li>
        By executing this Agreement and accessing EquiDeFi.com (the “Website”) I
        acknowledge and agree that EquiDeFi, Ltd., a Wyoming corporation, is not
        offering or selling any securities and merely provides certain services
        as a software vendor to the Company and is not responsible for the
        accuracy, adequacy or completeness of any Information. I agree to hold
        EquiDeFi, Ltd., and its officers, directors, consultants, owners,
        employees, and agents, harmless from and against any and all claims
        involving the Company or my use of the Website or any products or
        services offered therein that are subscribed or purchased by me or any
        of my affiliates
      </li>
    </ol>
  );
}

export default InvestmentNdaCopy;
