import { useMutation, useQuery } from "@tanstack/react-query";

import UserClient from "@equidefi/portals/clients/UserClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

/**
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useCurrentUser = (options = {}) => {
  const userApi = useApi(UserClient);

  return useQuery(["me"], () => userApi.me(), {
    ...options,
  });
};

/**
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useRegisteredUser = (options = {}) => {
  const userApi = useApi(UserClient);

  return useMutation((email) => userApi.registered(email), options);
};

/**
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useLoginUser = (options = {}) => {
  const userApi = useApi(UserClient);

  return useMutation((data) => userApi.login(data), options);
};

export const useChangePassword = () => {
  const userApi = useApi(UserClient);

  return useMutation((data) => userApi.changePassword(data));
};

// this is used in the password reset page
export const useSetNewPassword = () => {
  const userApi = useApi(UserClient);

  return useMutation((data) => userApi.setNewPassword(data));
};
