import { Box, Icon as CIcon, Center } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";
import React from "react";
import PersonaLogo from "../../../public/img/persona.svg";

export const InvestmentStatusTitle = ({ children, icon = null }) => {
  return (
    <Heading m="0" textStyle="h1" fontWeight="bold">
      {icon && <CIcon mr="2" color="primary" as={icon} />}
      {children}
    </Heading>
  );
};

export const InvestmentStatusText = ({
  children,
  muted = false,
  centered = false,
}) => {
  return <Text textStyle="body">{children}</Text>;
};

export const InvestmentStatusView = ({
  title,
  icon = null,
  muted = false,
  centered = false,
  children,
}) => {
  return (
    <>
      <InvestmentStatusTitle icon={icon}>{title}</InvestmentStatusTitle>
      <InvestmentStatusText muted={muted} centered={centered}>
        {children}
      </InvestmentStatusText>
      <Center mb="4" w="full">
        <Box
          as="object"
          data={PersonaLogo}
          type="image/svg+xml"
          aria-label="Powered by Persona"
          maxW="360px"
          alignSelf="center"
          data-aos="fade-up"
          data-aos-delay="100"
        />
      </Center>
    </>
  );
};
