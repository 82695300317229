export const investmentContinueUrl = (
  investmentId,
  next,
  offeringSlug,
  status
) => {
  if (status !== "PENDING") return null;

  let nextUri = null;

  // If no investmentId but offeringId is set, navigate to start screen
  if (!investmentId && offeringSlug) nextUri = "";

  if (
    next === "DILIGENCE" ||
    next === "COUNTERSIGN" ||
    next === "REFUND" ||
    next === "CLOSING"
  ) {
    nextUri = "complete";
  }

  // Determine initial uri to load
  if (next) {
    nextUri = `${investmentId}/${next.toLowerCase()}`;
  }

  return `/offerings/${offeringSlug}/${nextUri}`;
};
