import { Flex, useRadioGroup } from "@chakra-ui/react";

import { Radio } from "./Radio";

type RadioGroupProps<T extends string = string> = {
  fieldName: T;
  defaultValue?: string;
  options: Record<string, unknown>[];
  errors?: Record<T, string>;
  setFieldValue: (name: T, value: unknown) => void;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  fieldName,
  defaultValue = undefined,
  options,
  errors,
  setFieldValue,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: fieldName,
    defaultValue,
    onChange: (value) => {
      setFieldValue(fieldName, value);
    },
  });
  const group = getRootProps();

  return (
    <Flex {...group} direction="column" w="full">
      {options.map((value) => {
        const radio = getRadioProps({ value: value.value });
        return (
          <Radio key={value.value} {...radio}>
            {value.label}
          </Radio>
        );
      })}
      {errors?.[fieldName] && (
        <div className="text-danger mb-4 fs-sm">{errors[fieldName]}</div>
      )}
    </Flex>
  );
};

export default RadioGroup;
