import { Box, useStyleConfig } from "@chakra-ui/react";

import { PaperProps } from "./PaperTheme";

export const Paper = (props: PaperProps) => {
  const { variant, ...rest } = props;
  const styles = useStyleConfig("Paper", { variant });

  return <Box __css={styles} {...rest} />;
};
