type UserWithName = {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

export function userFullName(user: UserWithName = {}) {
  return [user?.first_name, user?.middle_name, user?.last_name]
    .filter((s) => typeof s === "string")
    .map((s) => s?.trim() ?? null)
    .filter(Boolean)
    .join(" ");
}
