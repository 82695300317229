import { Box, Tooltip } from "@chakra-ui/react";

import { Text } from "@equidefi/ui";

import { ReinvestButton } from "./ReinvestButton";

export const ReinvestBox = ({ offeringId, disabled = false }) => {
  return (
    <Box
      borderRadius="lg"
      mt={4}
      p={4}
      bgColor="#cba3ff33"
      borderColor="accent"
      borderWidth="2px"
    >
      <Text my="0" mb="0" fontSize="xs" textTransform="uppercase">
        Invest Again
      </Text>
      <Text as="h3" mb="0" fontWeight="bold" fontSize="lg">
        Want to Invest Again?
      </Text>
      <Text mb="2" textStyle="body">
        You can start a new investment in under 10 minutes!
      </Text>
      <Tooltip
        isDisabled={!disabled}
        label="Once the accreditation documents have been uploaded reinvesting will be made available!"
        hasArrow
      >
        <Box w="full">
          <ReinvestButton
            isDisabled={disabled}
            w="full"
            offeringId={offeringId}
          >
            Start A New Investment
          </ReinvestButton>
        </Box>
      </Tooltip>
    </Box>
  );
};
