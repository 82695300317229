import {
  Input as CInput,
  InputProps as CInputProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  InputGroup,
} from "@chakra-ui/react";
import { FC, ReactNode, forwardRef } from "react";
import { Text } from "../Text";

export interface InputProps extends CInputProps {
  name: string;
  value?: any;
  helperText?: string;
  label: string;
  error?: string;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  inputRightElement?: ReactNode;
}

export const Input: FC<InputProps> = forwardRef(
  (
    {
      name,
      value,
      label,
      helperText,
      isDisabled = false,
      isRequired = false,
      isInvalid = false,
      error,
      labelProps = {},
      mb = "2",
      inputRightElement = null,
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        mt="1"
        mb={mb}
        size={rest?.size}
      >
        <FormLabel mt="0" p="0" fontSize="md" {...labelProps}>
          {label}
        </FormLabel>
        <InputGroup>
          <CInput
            ref={ref}
            name={name}
            value={value ?? ""}
            bg="white"
            borderWidth="1px"
            borderRadius="5px"
            pt="10px"
            pb="10px"
            m="0"
            type="text"
            {...rest}
          />
          {inputRightElement}
        </InputGroup>
        <FormErrorMessage>{error}</FormErrorMessage>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);
