import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

import { PhoneField } from "@equidefi/portals/components/Auth";
import { useUpdateCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Input } from "@equidefi/ui";

const profileSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "First name must be at least 2 characters")
    .required("Please enter your first name"),
  middle_name: Yup.string().nullable(),
  last_name: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .required("Please enter your last name"),
  company: Yup.string().nullable(),
  title: Yup.string().nullable(),
  email: Yup.string().email("Invalid email").required("Required."),
  phone: Yup.string()
    .required("Please enter your phone number")
    .test({
      name: "phone",
      message: "Must be a valid phone number",
      test: (value) => (!!value ? isValidPhoneNumber(value) : false),
    }),
});

export const ProfileModal = ({ modal, onSave, me }) => {
  const [spinner, setSpinner] = useState(false);
  const toast = useToast();
  const updateUser = useUpdateCurrentUser();

  const onSubmit = async (form) => {
    setSpinner(true);

    const data = {
      first_name: form.first_name,
      middle_name: form.middle_name,
      last_name: form.last_name,
      email: form.email.toLowerCase().trim(),
      phone: form.phone,
      company: form.company,
      title: form.title,
    };

    try {
      await updateUser.mutateAsync(data);
      toast({
        status: "success",
        description: `Your profile has been updated successfully`,
      });
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: e.response.data.errors.join(". "),
      });
    }
    setSpinner(false);
    onSave();
    modal.onClose();
  };

  const initialValues = {
    ...me,
    first_name: me?.first_name || "",
    last_name: me?.last_name || "",
    email: me?.email || "",
    phone: me?.phone || "",
  };

  const {
    errors,
    touched,
    getFieldProps,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: profileSchema,
    enableReinitialize: true,
    onSubmit,
  });

  if (!me) return null;

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent mx="4">
        <ModalHeader>Edit your profile</ModalHeader>
        <ModalCloseButton color="equidefi.red" />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Input
              label="First Name"
              placeholder="John"
              isRequired
              isInvalid={errors.first_name && touched.first_name}
              error={errors.first_name}
              {...getFieldProps("first_name")}
            />

            <Input
              label="Middle Name"
              isInvalid={errors.middle_name && touched.middle_name}
              error={errors.middle_name}
              {...getFieldProps("middle_name")}
            />

            <Input
              label="Last Name"
              isRequired
              isInvalid={errors.last_name && touched.last_name}
              error={errors.last_name}
              {...getFieldProps("last_name")}
            />

            <Input
              label="Title"
              placeholder="e.g. Investor"
              isInvalid={errors.title && touched.title}
              error={errors.title}
              {...getFieldProps("title")}
            />

            <Input
              label="Company"
              placeholder="e.g. EquiDeFi"
              isInvalid={errors.company && touched.company}
              error={errors.company}
              {...getFieldProps("company")}
            />

            <Input
              label="Email Address"
              type="email"
              placeholder="e.g. address@example.com"
              isReadOnly
              isDisabled
              {...getFieldProps("email")}
            />

            <PhoneField
              handleBlur={handleBlur}
              error={touched?.phone && errors?.phone}
              setFieldValue={setFieldValue}
              {...getFieldProps("phone")}
            />

            <Button type="submit" isLoading={spinner} w="full" my="2">
              Save Changes
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
