import { once } from "lodash";
import Intercom from "@intercom/messenger-js-sdk";

const APP_ID = "hyz6oa8h";

export const initialize = once(() => {
  Intercom({
    app_id: APP_ID,
  });
});

export const identify = once((user) => {
  Intercom({
    app_id: APP_ID,
    email: user.email,
    user_id: user.id,
    created_at: new Date().getTime(),
    user_hash: user.intercom_hmac,
  });
});
