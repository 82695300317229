import { ApiClient } from "./ApiClient";
import UserToken from "./UserToken";

export type TResetPasswordParams = {
  email: string;
  source: "investor" | "issuer";
  redirect_to?: string;
};

class UserClient extends ApiClient {
  async me() {
    const token = UserToken.token();
    if (!token) return null;
    const response = await this.get("/v2/user/me");
    return response.data;
  }

  async register(
    data: Record<string, any>,
    type: string = "offering",
    token: string
  ) {
    const response = await this.post(
      `/v2/users/register/${type}?token=${token}`,
      data
    );
    return response.data;
  }

  async invite(data: Record<string, any>) {
    const response = await this.post("/v2/users/invite", data);
    return response.data;
  }

  async changePassword(payload: Record<string, any>) {
    const response = await this.post("/v2/users/changePassword", payload);
    return response.data;
  }

  async setNewPassword(payload: Record<string, any>) {
    const response = await this.post("/v2/users/setNewPassword", payload);
    return response.data;
  }

  async mfa(data: Record<string, any>) {
    const response = await this.post("/v2/users/mfa", data);
    return response.data;
  }

  async auth(data: Record<string, any>) {
    const response = await this.post("/v2/users/auth", data);
    return response.data;
  }

  async login(data: Record<string, any>) {
    const response = await this.post("/v2/users/login", data);
    return response.data;
  }

  async update(data: Record<string, any>) {
    const response = await this.post("/v2/user/me", data);
    return response.data;
  }

  async getById(userId: string) {
    const response = await this.get(`/v2/user/${userId}`);
    return response.data;
  }

  async updateById(userId: string, data: Record<string, any>) {
    const response = await this.put(`/v2/user/${userId}`, data);
    return response.data;
  }

  async registered(email: string) {
    const response = await this.post(`/v2/users/user`, {
      email: email,
    });
    return response.data;
  }

  async invitation(type: string, data: object) {
    const response = await this.post(
      `/v2/users/invitation/${type.toLowerCase()}`,
      data
    );
    return response.data;
  }

  async sendResetPasswordEmail(data: TResetPasswordParams) {
    const response = await this.post(`/v2/users/auth/reset/`, data);
    return response.data;
  }
}

export default UserClient;
