export const stepsRegD = [
  // Order is important
  "PROFILE",
  "SUBSCRIPTION",
  "PAYMENT",
  "ADDRESS",
  "SIGNERS",
  "KYC",
  "AGREEMENT",
  "ACCREDITATION",
  "DILIGENCE",
  "COUNTERSIGN",
  "CLOSING",
];

export const stepsRegA = [
  // Order is important
  "PROFILE",
  "SUBSCRIPTION",
  "PAYMENT",
  "ADDRESS",
  "SIGNERS",
  "KYC",
  "AGREEMENT",
  "COUNTERSIGN",
  "CLOSING",
];

export const investmentStepsCopy = {
  PROFILE: {
    text: "The investor must provide the necessary information to generate the agreements.",
    heading: "Profile",
  },
  SUBSCRIPTION: {
    text: "The investor must indicate an investment amount.",
    heading: "Subscription",
  },
  PAYMENT: {
    text: "The investor must complete a payment for the investment.",
    heading: "Payment",
  },
  ADDRESS: {
    text: "The investor must enter their legal address.",
    heading: "Address",
  },
  SIGNERS: {
    text: "The investor may add additional signers for the agreement.",
    heading: "Signers",
  },
  KYC: {
    text: "The investor must complete the Identity Check process.",
    heading: "Identity Check",
  },
  AGREEMENT: {
    text: "The investment signers must sign the agreements.",
    heading: "Agreements",
  },
  ACCREDITATION: {
    text: "The investor must upload documents that prove their accreditation status.",
    heading: "Accreditation",
  },
  DILIGENCE: {
    text: "The investor has completed the investment process. The issuer must review the investment.",
    heading: "Diligence",
  },
  COUNTERSIGN: {
    text: "The investment was approved and will be countersigned by the issuer.",
    heading: "Countersigning",
  },
  CLOSING: {
    text: "The investment has been countersigned by the issuer. Shares will be issued in the next closing tranche.",
    heading: "Closing",
  },
};
