import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useToken,
} from "@chakra-ui/react";
import { FC, ReactNode, forwardRef, useState } from "react";
import { Icon } from "../icon";

export interface PasswordProps extends InputProps {
  name: string;
  value?: any;
  label: string;
  error?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  helperText?: ReactNode;
}

export const Password: FC<PasswordProps> = forwardRef(
  (
    {
      name,
      label,
      value,
      isDisabled = false,
      isRequired = false,
      isInvalid = false,
      error,
      helperText,
      mb = 5,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const PasswordIcon = !showPassword ? Icon.EyeOff : Icon.Eye;

    const [gray] = useToken("colors", ["gray.400"]);

    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        mb={mb}
      >
        <FormLabel>{label}</FormLabel>
        <InputGroup>
          <Input
            ref={ref}
            type={showPassword ? "text" : "password"}
            name={name}
            value={value ?? undefined}
            {...rest}
          />
          <InputRightElement alignItems="center" h="full">
            <IconButton
              variant="link"
              aria-label={showPassword ? "Hide Password" : "Show Password"}
              color="gray.400"
              icon={
                <PasswordIcon
                  onClick={() => setShowPassword((prev) => !prev)}
                  size="1em"
                />
              }
            />
          </InputRightElement>
        </InputGroup>
        <FormHelperText>{helperText}</FormHelperText>
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    );
  }
);
