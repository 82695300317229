import { VStack } from "@chakra-ui/react";
import { InvestmentSignatureBox } from "./InvestmentSignatureBox";

const InvestmentSignatureList = ({
  investmentId,
  investors,
  onEdit,
  onDelete,
  canEditSigners,
}) => {
  return (
    <VStack as="ul" gap="4" p={0} maxWidth="full">
      {investors?.map((signature, i) => (
        <InvestmentSignatureBox
          key={signature.signature_id ?? i}
          investmentId={investmentId}
          signature={signature}
          onEdit={onEdit}
          onDelete={onDelete}
          canEditSigners={canEditSigners}
        />
      ))}
    </VStack>
  );
};

export default InvestmentSignatureList;
