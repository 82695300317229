import React from "react";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";
import { useRouteError } from "react-router-dom";
import NavBar from "../components/nav/NavBar";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Stack
      as="main"
      bgColor="background"
      direction={{ base: "column", lg: "row" }}
    >
      <NavBar>
        <Flex
          direction="column"
          align="center"
          justify={{ base: "start", lg: "center" }}
          w="full"
          minH="100vh"
        >
          <Box maxWidth="560px" w="full" p={4} as="form">
            <Heading fontWeight="bold" mb={2}>
              Oops!
            </Heading>
            <Text mb={6}>
              Sorry, an unexpected error has occurred. Please contact{" "}
              <a href="/support">
                <u>support</u>
              </a>{" "}
              if you require assistance.
            </Text>
          </Box>
        </Flex>
      </NavBar>
    </Stack>
  );
}
