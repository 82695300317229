import { ApiClient } from "@equidefi/portals/clients";

import { ApiInstance } from "./api";

class StripeClient extends ApiClient {
  async togglePaymentIntentType(investmentId, paymentIntentId, type) {
    const response = await this.post(
      `/v2/stripe/payment-intent/method/${investmentId}`,
      {
        type,
        payment_intent_id: paymentIntentId,
      }
    );
    return response.data;
  }

  /**
  /**
   * @param {string} offeringId
   * @return {Promise<object>}
   */
  static async createPaymentIntent(investmentId) {
    const response = await ApiInstance.post(
      `/v2/stripe/payment-intent/${investmentId}`
    );
    return response.data;
  }
}

export default StripeClient;
