import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";

import { Icon } from "@equidefi/ui/icon";

import AgreementClient from "../../clients/AgreementClient";
import { useApi } from "../../hooks/useApi";

type DownloadButtonProps = ButtonProps & {
  investmentId: string | null | undefined;
};

const useDownloadButton = (investmentId: string | null | undefined) => {
  const [loading, setLoading] = useState<boolean>(false);
  const agreementApi = useApi(AgreementClient);

  const handleOnClick = async (e: React.SyntheticEvent) => {
    if (!investmentId) return;

    e.preventDefault();

    setLoading(true);
    try {
      const data = await agreementApi.downloadAgreement(investmentId as string);
      window.open(data.fileUrl, "_blank");
    } finally {
      setLoading(false);
    }
  };

  return {
    onClick: handleOnClick,
    isDisabled: !investmentId,
    isLoading: loading,
  };
};

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  investmentId,
  ...props
}) => {
  const buttonProps = useDownloadButton(investmentId);

  if (!investmentId) {
    return null;
  }

  return <Button {...props} {...buttonProps} />;
};

export const DownloadIconButton: React.FC<DownloadButtonProps> = ({
  investmentId,
  ...props
}) => {
  const buttonProps = useDownloadButton(investmentId);

  return (
    <IconButton
      icon={<Icon.Download size="1.5em" />}
      variant="unstyled"
      aria-label="Download"
      display="inline-flex"
      _hover={{ bg: "none" }}
      _disabled={{ color: "gray.300", bg: "none" }}
      _loading={{ bg: "none" }}
      _active={{ bg: "none" }}
      _focus={{ bg: "none" }}
      _pressed={{ bg: "none" }}
      color="equidefi.blue"
      {...buttonProps}
      {...props}
    />
  );
};

export default DownloadButton;
