import { BoxProps, defineStyleConfig } from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

export type PaperVariants = "base" | "primary" | "secondary" | string;
export interface PaperProps extends BoxProps {
  variant?: PaperVariants;
}

export const PaperTheme = defineStyleConfig({
  baseStyle: {
    borderRadius: "lg",
    background: "white",
    boxShadow: "paper",
  },
  sizes: {},
  variants: {
    primary: {
      backgroundColor: transparentize("equidefi.green", 0.2),
      borderColor: "equidefi.green",
      borderWidth: "1px",
      borderRadius: "lg",
      boxShadow: "none",
    },
    secondary: {
      backgroundColor: transparentize("equidefi.yellow", 0.2),
      borderColor: "equidefi.yellow",
      borderWidth: "1px",
      borderRadius: "lg",
      boxShadow: "none",
    },
  },
  defaultProps: {},
});
