import { loadStripe } from "@stripe/stripe-js";

import { CONFIG } from "./config";

export const stripePromise = loadStripe(CONFIG.STRIPE_EST_PUBLISHABLE_KEY);

export const stripeAccountPromise = async (stripeAccount) => {
  return loadStripe(CONFIG.STRIPE_EST_PUBLISHABLE_KEY, {
    stripeAccount,
  });
};

/** @type {import("@stripe/stripe-js").Appearance} */
export const APPEARANCE = {
  theme: "stripe",
  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#30313d",
    colorDanger: "#df1b41",
    fontFamily: "Ideal Sans, system-ui, sans-serif",
    spacingUnit: "2px",
    borderRadius: "4px",
  },
};
