import { Flex, FlexProps } from "@chakra-ui/react";

export const CardList = (props: FlexProps) => {
  const { children, ...rest } = props;

  return (
    <Flex gap="2.5" direction="column" {...rest}>
      {children}
    </Flex>
  );
};
