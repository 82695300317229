import { Button, useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { useStartReinvestment } from "@equidefi/portals/hooks/useInvestments";
import { useOffering } from "../../hooks/useOfferings";

export const useReinvestButton = (slug) => {
  const startReinvestment = useStartReinvestment();
  const navigate = useNavigate();
  const toast = useToast();

  /**
   * @param {string} offeringId
   * @param {string?} bcode
   * @returns void
   */
  const onReinvest = useCallback(
    async (offeringId, bcode) => {
      try {
        const investment = await startReinvestment.mutateAsync({
          offering_id: offeringId,
          bcode: `${bcode}`,
        });

        toast({
          status: "success",
          description: "Your new investment was created, let's get started!",
        });

        navigate(`/offerings/${slug}/${investment.id}/subscription`);
      } catch (e) {
        console.error(e);
        toast({
          status: "error",
          description:
            "Something went wrong. We could not start another investment at the time.",
        });
      }
    },
    [navigate, slug, startReinvestment, toast]
  );

  return {
    onReinvest,
    isLoading: startReinvestment.isLoading,
  };
};

export const ReinvestButton = ({ offeringId, ...rest }) => {
  const { data: offering } = useOffering(offeringId);
  const cookies = useCookies(["bcode"]);
  const button = useReinvestButton(offering?.slug);

  return (
    <Button
      isLoading={button.isLoading}
      {...rest}
      onClick={() => button.onReinvest(offeringId, cookies.bcode)}
    />
  );
};
