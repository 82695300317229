import { Box, HStack, Image, VStack } from "@chakra-ui/react";
import { Heading } from "@equidefi/ui";
import EquidefiLogo from "../../images/logo-white.png";
import { useIsMobile } from "../../hooks/useIsMobile";
import LoginImage from "../../../public/img/login.jpg";

const LoginContainer = (props) => {
  const isMobile = useIsMobile("sm");

  return (
    <Box minW="full" minH="full" m="0" p="0" bgColor="primary">
      <HStack minW="full" minH="full">
        <VStack
          minH="100vh"
          minW={{ base: "100%", sm: "400px" }}
          justify="space-between"
          px="10"
          py="20"
        >
          <VStack>
            <Image src={EquidefiLogo} width="200px" alt="EquiDeFi" />
            <Heading
              color="white"
              opacity="70%"
              letterSpacing="5px"
              textTransform="uppercase"
            >
              Investor Portal
            </Heading>
          </VStack>

          {props.children}
        </VStack>
        <Image
          src={LoginImage}
          hidden={isMobile}
          minH="100vh"
          objectFit="cover"
          backgroundRepeat="no-repeat"
        />
      </HStack>
    </Box>
  );
};

export default LoginContainer;
