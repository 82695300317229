import { useMutation } from "@tanstack/react-query";

import UserClient from "../clients/UserClient";
import UserToken from "../clients/UserToken";

import { useApi } from "./useApi";

interface Login {
  email: string;
  password: string;
}

interface MFA {
  email: string;
  code: number;
}

interface Register {
  first_name: string;
  middle_name?: string;
  last_name: string;
  email: string;
  phone?: string;
  company?: string;
  entity_type: string;
  accept_tos?: Date;
  accept_privacy?: Date;
  accept_sms_email?: Date;
  token?: string;
}

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useLogin = (options = {}) => {
  const userApi = useApi(UserClient);

  return useMutation(async (data: Login) => userApi.auth(data), options);
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useMFACode = (options = {}) => {
  const userApi = useApi(UserClient);

  return useMutation(async (data: MFA) => userApi.mfa(data), options);
};

export const useRegister = (type, token) => {
  const userApi = useApi(UserClient);

  return useMutation(async (data: Register) => {
    const user = await userApi.register(data, type, token);

    UserToken.setToken(user.token);

    return user;
  });
};
