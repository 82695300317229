import { useMutation, useQuery } from "@tanstack/react-query";
import IntercomClient from "../clients/IntercomClient";

export const useIntercomCreateTicket = (options) => {
  return useMutation((data) => IntercomClient.createTicket(data, options));
};

export const useIntercomGetTicketTypes = (options) => {
  return useQuery(
    ["ticket-types"],
    () => IntercomClient.getTicketTypes(),
    options
  );
};
