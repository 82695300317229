export const PaymentReceiptStyle = {
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
    fontFamily: "Helvetica",
    lineHeight: 1.4,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  title: {
    fontSize: 24,
  },
  text: {
    fontSize: 14,
    marginBottom: 5,
  },
  table: {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#808080",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#E0E0E0",
  },
  tableCell: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#808080",
    textAlign: "left",
    fontSize: 12,
    padding: 5,
    wordBreak: "break-word",
    flexWrap: "wrap",
  },
  tableFirstCell: {
    width: "20%",
    minWidth: "20%",
    maxWidth: "20%",
  },
};
