import {
  Alert,
  AlertDescription,
  AlertIcon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { WorkflowButton } from "../../../components/workflow/WorkflowButton";
import { Heading, Text } from "@equidefi/ui";
import Confetti from "react-confetti";

export const CompletedModal = ({ modal, isManualPayment }) => {
  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      aria-labelledby="contained-modal-title-vcenter"
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay>
        <Confetti
          style={{ position: "fixed", width: "100vw", height: "100vh" }}
        />
      </ModalOverlay>
      <ModalContent mx="4">
        <ModalHeader>
          <Heading fontWeight="bold" mb="0">
            Thank you for your investment!
          </Heading>
          <ModalCloseButton color="equidefi.red" />
        </ModalHeader>
        <ModalBody>
          <Text m="0">
            You can review the status of your investment(s) here in your Vault.
            You will be notified once your investment has been closed.
          </Text>

          {isManualPayment && (
            <Alert
              status="warning"
              borderRadius="lg"
              border="1px solid"
              borderColor="equidefi.yellow"
              boxShadow="sm"
              mt={4}
            >
              <AlertIcon />
              <AlertDescription>
                <Text textStyle="body2" m="0">
                  If you chose to make your payment by check or wire transfer,
                  please remember to submit your check/wire transfer in order
                  for us to complete your investment.
                </Text>
              </AlertDescription>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <WorkflowButton onClick={modal.onClose}>
            Continue to Vault
          </WorkflowButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
