export const regex_phone =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const regex_website =
  /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;
export const regex_postal_code = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate.getTime() < selectedDate.getTime();
};

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function toMoney(data) {
  if (data % 1 !== 0) {
    data = Math.round((data + Number.EPSILON) * 100) / 100;
  }
  return data;
}

export function toPercent(data) {
  if (data > 100) {
    let result = parseInt(String(data).slice(0, 3));
    if (result > 100) {
      return parseInt(String(data).slice(0, 2));
    } else {
      return result;
    }
  } else {
    return data;
  }
}

export const calendarLimit = (_dateValue) => {
  const minDate = _dateValue ? new Date(_dateValue) : new Date();
  minDate.setDate(minDate.getDate() + 1);
  const maxDate = _dateValue ? new Date(_dateValue) : new Date();
  maxDate.setDate(maxDate.getDate() + 364);

  return {
    minDate,
    maxDate,
  };
};
