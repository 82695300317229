import { useQuery } from "@tanstack/react-query";

import GoogleClient from "@equidefi/portals/clients/GoogleClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

export const useGoogleAddressSearch = (search, options = {}) => {
  const googleApi = useApi(GoogleClient);

  return useQuery(
    ["places-search", search],
    async () => {
      const data = await googleApi.autocomplete(search);
      return data ?? [];
    },
    {
      enabled: !!search,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      ...options,
    }
  );
};
