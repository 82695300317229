import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
// import { update } from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { identify } from "../helpers/intercom";

export const useIntercomMessenger = (hideDefault = true) => {
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (user?.intercom_hmac) {
      identify(user);
    }
  }, [user?.intercom_hmac]);

  // useEffect(() => {
  //   update({
  //     alignment: hideDefault ? "left" : "right",
  //     hide_default_launcher: hideDefault,
  //     custom_launcher_selector: ".intercom",
  //   });
  // }, [hideDefault]);
};
