import { defineStyle, type SystemStyleInterpolation } from "@chakra-ui/react";

const heading = (
  other?: SystemStyleInterpolation
): ReturnType<typeof defineStyle> =>
  defineStyle({
    fontFamily: "heading",
    lineHeight: "150%",
    opacity: "90%",
    ...other,
  });

const body = (
  other?: SystemStyleInterpolation
): ReturnType<typeof defineStyle> =>
  defineStyle({
    fontFamily: "body",
    lineHeight: "150%",
    opacity: "90%",
    ...other,
  });

export const textStyles = {
  h1: heading({ fontSize: "1.375em" }),
  h2: heading({ fontSize: "lg" }),
  h3: heading({ fontSize: "md" }),
  body: body({ fontSize: "md" }),
  body2: body({ fontSize: "sm" }),
  context: body({ fontSize: "sm", color: "secondary" }),
  subtext: body({ fontSize: "xs" }),
};
