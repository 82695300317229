// import { toast } from "react-toastify";

import type { AxiosInstance, AxiosRequestConfig } from "axios";

export class ApiClient {
  instance: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.instance = api;
  }

  async get<T = any>(...args: Parameters<AxiosInstance["get"]>) {
    return this.instance.get<T>(...args);
  }

  async post<T = any>(...args: Parameters<AxiosInstance["post"]>) {
    return this.instance.post<T>(...args);
  }

  async put<T = any>(...args: Parameters<AxiosInstance["put"]>) {
    return this.instance.put<T>(...args);
  }

  async head<T = any>(...args: Parameters<AxiosInstance["head"]>) {
    return this.instance.head<T>(...args);
  }

  async patch<T = any>(...args: Parameters<AxiosInstance["patch"]>) {
    return this.instance.patch<T>(...args);
  }

  async delete<T = any>(...args: Parameters<AxiosInstance["delete"]>) {
    return this.instance.delete<T>(...args);
  }

  async options<T = any>(...args: Parameters<AxiosInstance["options"]>) {
    return this.instance.options<T>(...args);
  }

  async request<T = any>(config: AxiosRequestConfig) {
    return this.instance.request<T>(config);
  }
}
