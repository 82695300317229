import React from "react";
import NumberFormat from "react-number-format";

import { NumberFormatter } from "../../../../constants/format";
import { Heading } from "@equidefi/ui";
import InvestmentDisplayItem from "./InvestmentDisplayItem";

const OfferingInfoCard = ({ offering }) => {
  return (
    <>
      <Heading fontWeight="bold" mb="1">
        {offering?.name}
      </Heading>
      <InvestmentDisplayItem
        label="Stock Ticker"
        value={offering?.stock_ticker ?? "N/A"}
      />

      <InvestmentDisplayItem
        label="Total amount"
        value={
          <NumberFormat
            value={offering?.total_amount}
            displayType="text"
            thousandSeparator={true}
            prefix="$"
          />
        }
      />

      {(offering?.type === "COMMON_STOCK" ||
        offering?.type === "PREFERRED_STOCK") && (
        <InvestmentDisplayItem
          label="Total shares"
          value={NumberFormatter.format(offering?.total_units)}
        />
      )}
      <InvestmentDisplayItem
        label="Price per share"
        value={
          <NumberFormat
            value={offering?.unit_price}
            displayType="text"
            thousandSeparator={true}
            prefix="$"
          />
        }
      />
    </>
  );
};

export default OfferingInfoCard;
