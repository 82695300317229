import { capitalizeEveryWord } from "../helpers/string";
export const PRO_LETTER: string = "PRO_LETTER";
export const INCOME_VERIFICATION: string = "INCOME_VERIFICATION";
export const ASSET_VERIFICATION: string = "ASSET_VERIFICATION";

export const ACCREDITATION_OPTIONS = [
  { label: "Accredited Investor Verification Letter", value: PRO_LETTER },
  { label: "Income Verification", value: INCOME_VERIFICATION },
  { label: "Asset Verification", value: ASSET_VERIFICATION },
];

export const ASSET_OPTIONS: { label: string; value: string }[] = [
  {
    label: "Bank Statement",
    value: "BANK_STATEMENT",
  },
  {
    label: "Brokerage Statement",
    value: "BROKERAGE_STATEMENT",
  },
  {
    label: "Corporate 1120",
    value: "CORPORATE_1120",
  },
  {
    label: "Employee Benefit Plan",
    value: "EMPLOYEE_BENEFIT_PLAN",
  },
  {
    label: "Entity Assets",
    value: "ENTITY_ASSETS",
  },
  {
    label: "Trust Assets",
    value: "TRUST_ASSETS",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const INCOME_OPTIONS: { label: string; value: string }[] = [
  {
    label: "1040 (US Citizen)",
    value: "PERSONAL_1040",
  },
  {
    label: "Other (US Citizen)",
    value: "PERSONAL_US_OTHER",
  },
  {
    label: "W-8 (Non-US Citizen)",
    value: "PERSONAL_W8",
  },
  {
    label: "Other (Non-US Citizen)",
    value: "PERSONAL_NON_US_OTHER",
  },
];

export const PENDING = "PENDING";
export const SUBMITTED = "SUBMITTED";
export const APPROVED = "APPROVED";
export const REJECTED = "REJECTED";

export const STATUS_OPTIONS: { label: string; value: string }[] = [
  {
    label: capitalizeEveryWord(PENDING),
    value: PENDING,
  },
  {
    label: capitalizeEveryWord(SUBMITTED),
    value: SUBMITTED,
  },
  {
    label: capitalizeEveryWord(APPROVED),
    value: APPROVED,
  },
  {
    label: capitalizeEveryWord(REJECTED),
    value: REJECTED,
  },
];
