import NumberFormat from "react-number-format";
import React from "react";
import { Flex, HStack, InputRightElement } from "@chakra-ui/react";
import { Input, Text } from "@equidefi/ui";
import { CurrencyFormatter } from "../../helpers/format";

export default function CommonStockForm({
  values,
  handleBlur,
  setValues,
  errors,
  touched,
  offering,
}) {
  const unitPrice = Number(offering?.unit_price);

  return (
    <Flex direction="column">
      <Input
        maxW="50%"
        label="Enter number of shares"
        isInvalid={errors.quantity && touched.quantity}
        as={NumberFormat}
        name="quantity"
        allowNegative={false}
        thousandSeparator
        value={values.quantity}
        onBlur={handleBlur}
        onValueChange={({ value }) => {
          setValues((current) => ({
            ...current,
            quantity: value,
            amount: value * unitPrice,
          }));
        }}
        decimalScale={0}
        fixedDecimalScale={0}
        error={errors.quantity}
        inputRightElement={
          <InputRightElement left="50%" ml="2" justifyContent="flex-start">
            <Text whiteSpace="nowrap" mb="0">
              {"x " + CurrencyFormatter.format(unitPrice)}
            </Text>
          </InputRightElement>
        }
      />

      <HStack>
        <Text fontWeight="bold">Total investment amount:</Text>
        <Text fontWeight="normal">
          {CurrencyFormatter.format(values.amount)}
        </Text>
      </HStack>
    </Flex>
  );
}
