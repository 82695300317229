import { useBreakpointValue } from "@chakra-ui/react";

export const useIsMobile = (desktopBreakpoint = "lg") => {
  return useBreakpointValue(
    {
      base: true,
      [desktopBreakpoint]: false,
    },
    {
      fallback: false,
    }
  );
};
