import { useEffect, useRef } from "react";

const BASE_TITLE = "Investor Portal - Powered by EquiDeFi";

/**
 * @param {string | string[]} pieces
 */
export const useDocumentTitle = (pieces = []) => {
  const prevTitle = useRef(document.title);

  useEffect(() => {
    try {
      /**
       * @type {string | string[]}
       *
       * This allows use the flexibility of passing in an array OR a single string to the
       * hook so that it allows for dynamic title pieces without needing to handle string
       * concatenation in other places
       */
      const start = Array.isArray(pieces) ? pieces : [pieces];
      const title = [...start.filter(Boolean)].filter(Boolean).join(" - ");

      document.title = [title, BASE_TITLE].filter(Boolean).join(" | ");
    } catch (err) {
      document.title = prevTitle.current;
    }

    return () => {
      document.title = prevTitle.current;
    };
  }, []);
};
