import { useBoolean, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Persona from "persona";

import InquiryClient from "@equidefi/portals/clients/InquiryClient";
import { useApi } from "@equidefi/portals/hooks/useApi";
import { CONFIG } from "../constants/config";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInquiry = (id, options = {}) => {
  const inquiryApi = useApi(InquiryClient);

  return useQuery(["inquiry", id], () => inquiryApi.find(id), {
    enabled: !!id,
    ...options,
  });
};

export const useResumeInquiry = (options = {}) => {
  const api = useApi(InquiryClient);

  return useMutation(async ({ inquiryId }) => api.resume(inquiryId), options);
};

export const useEmbedInvestmentInquiry = (
  investmentId,
  { onComplete = () => {}, onCancel = () => {}, onError = () => {} }
) => {
  const inquiryApi = useApi(InquiryClient);
  const toast = useToast();
  const [isEmbedLoading, embedLoading] = useBoolean(false);

  const onStart = async () => {
    embedLoading.on();

    try {
      const { data } = await inquiryApi.embed({
        investment_id: investmentId,
      });

      const params = data.session_token
        ? {
            inquiryId: data.inquiry_id,
            sessionToken: data.session_token,
          }
        : {
            templateId: data.template_id,
          };

      const client = new Persona.Client({
        ...params,
        referenceId: data.reference_id,
        fields: {
          ...data.fields,
        },
        environmentId: CONFIG.PERSONA_ENV,
        onReady: () => {
          embedLoading.off();
          client.open();
        },
        onComplete: ({ inquiryId, status, fields }) => {
          onComplete({ inquiryId, status, fields });
        },
        onCancel: ({ inquiryId, sessionToken }) => {
          onCancel({ inquiryId, sessionToken });
        },
        onError: (error) => {
          onError(error);
          embedLoading.off();
          console.error(error);
        },
      });
    } catch (error) {
      embedLoading.off();
      console.error(error);
      toast({
        status: "error",
        description:
          error.response?.data?.errors?.join(". ") ?? "Something went wrong.",
      });
    }
  };

  return {
    onStart,
    isLoading: isEmbedLoading,
  };
};
