import { Alert, Box } from "@chakra-ui/react";
import { Heading, Paper, Text } from "@equidefi/ui";

export const InvestmentClosed = () => {
  return (
    <Paper mt="4">
      <Alert
        status="success"
        w="full"
        p="4"
        borderRadius="8px"
        border="2px solid"
        borderColor="equidefi.green"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Heading mb="2.5" fontWeight="bold">
          Your Investment Is Complete
        </Heading>
        <Text textStyle="body2" m="0">
          Your investment is complete. You will receive an email notification
          regarding the issuance of your shares.
        </Text>
      </Alert>
    </Paper>
  );
};
