import { createStore } from "redux";

const initial_state = {
  email: "",
  token: "",
  user: {},
  offering: {},
  investment: {},
  investor: {},
};

const appReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "email":
      return { ...state, email: action.payload };
    case "token":
      return { ...state, token: action.payload };
    case "user":
      return { ...state, user: action.payload };
    case "investment":
      return { ...state, investment: action.payload };
    case "offering":
      return { ...state, offering: action.payload };
    case "investor":
      return { ...state, investor: action.payload };
    default:
      return state;
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("eq-investor");
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("eq-investor", serializedState);
  } catch (err) {
    console.log(err);
  }
};

const store = createStore(
  appReducer,
  loadState(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
