import axios from "axios";
import { toast } from "react-toastify";

export const handleApiError = (error) => {
  console.log(error);
  toast.error(
    "Oops! There was an error trying to process your request. Please try again or contact admin."
  );
};

export const getSettingsByOfferingId = (offeringId) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/offering/${offeringId}/setting`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getInvestReadyUrl = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/webhook/investready/url`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const isConvertibleOffering = (stockType) => {
  return (
    stockType === "convertible_note" ||
    stockType === "In lieu of cash with Convertible Notes" ||
    stockType?.includes("Unit with convertible notes")
  );
};

export const isRegD506COffering = (exemption_type) => {
  return exemption_type === "REG_D_506_C";
};

export const isCommonStockType = (stockType) => {
  if (!stockType) return false;

  return (
    stockType === "common_stock" ||
    stockType.includes("Common Stock") ||
    stockType === "preferred_stock" ||
    stockType.includes("Preferred Stock") ||
    stockType?.includes("In lieu of cash with common stock") ||
    stockType.includes("Unit with common stock")
  );
};

export const addInvestmentBroker = async (investmentId, brokerId) => {
  const response = await axios.post(
    `/v2/investments/${investmentId}/broker/${brokerId}`
  );
  return response.data;
};

export const removeInvestmentBroker = async (investmentId) => {
  const response = await axios.delete(`/v2/investments/${investmentId}/broker`);
  return response.data;
};
