import { Alert, AlertIcon } from "@chakra-ui/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Text } from "@equidefi/ui";
import { WorkflowContext } from "../context";
import { WorkflowContainer } from "../WorkflowContainer";

const SubscriptionError = () => {
  const { offering, investment } = useContext(WorkflowContext);
  const navigate = useNavigate();

  const resumeInvestment = () => {
    navigate(
      `/offerings/${offering.slug}/${
        investment?.id
      }/${investment.next.toLowerCase()}`
    );
  };

  return (
    <WorkflowContainer onContinue={resumeInvestment} title="Investment Amount">
      <Alert
        status="error"
        borderRadius="lg"
        border="1px solid"
        borderColor="equidefi.red"
        boxShadow="sm"
        my={4}
      >
        <AlertIcon />
        <Text textStyle="body2" m={0}>
          Your subscription has already been entered and a payment has been
          submitted, you can no longer edit the subscription amount for this
          investment
        </Text>
      </Alert>
    </WorkflowContainer>
  );
};

export default SubscriptionError;
