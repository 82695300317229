import { Box, Button, useClipboard } from "@chakra-ui/react";
import React from "react";

import { CONFIG } from "../../../../portals/issuer/src/constants/config";
import { Icon } from "@equidefi/ui/icon";

const SigningURL = ({ investmentAgreementId, signatureId }) => {
  const url = new URL(
    `/investment/signer/${investmentAgreementId}/${signatureId}`,
    CONFIG.EQ_APP_URL
  );

  const shareUrl = useClipboard(url.toString());

  return (
    <Button
      color="equidefi.blue"
      variant="none"
      size="sm"
      gap={1}
      ml="0"
      p="0"
      minW="25%"
      h="full"
      alignItems="center"
      justifyContent={{ base: "start", md: "end" }}
      alignSelf={{ base: "start", md: "end" }}
      flexFlow="row nowrap"
      textAlign={{ base: "left", md: "right" }}
      onClick={shareUrl.onCopy}
    >
      <span>{shareUrl.hasCopied ? "Copied!" : "Signing URL"}</span>
      <Box as="span" flexShrink="0">
        <Icon.Clipboard size="1em" />
      </Box>
    </Button>
  );
};

export default SigningURL;
