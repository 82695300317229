import analytics from "analytics";
import { useMemo } from "react";

import gaAnalytics from "@analytics/google-analytics";
import gtmAnalytics from "@analytics/google-tag-manager";

const APP_NAME = "eq-investor-portal";

export const useOfferingAnalytics = (offering) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ANALYTICS_CONFIG_MAP = {
    GOOGLE_TAG_MANAGER: (config) => gtmAnalytics({ containerId: config.key }),
    GOOGLE_ANALYTICS: (config) => gaAnalytics({ measurementIds: [config.key] }),
  };

  const plugins = useMemo(() => {
    if (!offering) return [];

    return (
      offering?.analytics
        ?.map((config) => {
          const analyticDefinition = ANALYTICS_CONFIG_MAP[config.type];
          if (!analyticDefinition) return false;

          return analyticDefinition(config);
        })
        ?.filter(Boolean) ?? []
    );
  }, [ANALYTICS_CONFIG_MAP, offering]);

  return useMemo(
    () =>
      analytics({
        app: APP_NAME,
        plugins,
      }),
    [plugins]
  );
};
