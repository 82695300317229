import { useContext, useEffect } from "react";
import { WorkflowContext } from "../pages/workflow/context";

export default function useWorkflowPosition(index) {
  const { setPosition } = useContext(WorkflowContext);

  useEffect(() => {
    setPosition(index);
  }, []);
}
