import { FormControl } from "@chakra-ui/react";
import { useState } from "react";
import Select from "react-select";

import GoogleClient from "@equidefi/portals/clients/GoogleClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

import { useGoogleAddressSearch } from "../../../../../issuer/src/hooks/useAddressSearch";
import useDebounce from "../../../hooks/use-debounce";

export const GooglePlaceSearch = ({ onSelected }) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);
  const { data: options, isFetching } = useGoogleAddressSearch(searchVal);

  const googleApi = useApi(GoogleClient);
  const handleOnSelect = async (option) => {
    const data = await googleApi.place(option.value);
    onSelected(data);
  };

  return (
    <FormControl mb={4}>
      <Select
        autoFocus={true}
        placeholder={"Search any address (including international)"}
        noOptionsMessage={() => "No addresses found"}
        filterOption={null}
        onInputChange={(value) => {
          setInputSearchValue(value);
          return value;
        }}
        onChange={handleOnSelect}
        isLoading={isFetching}
        options={options}
        components={{
          IndicatorSeparator: null,
        }}
      />
    </FormControl>
  );
};
