import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import UserToken from "@equidefi/portals/clients/UserToken";
import { offeringIsCrowdfunded } from "@equidefi/shared";

import { investmentContinueUrl } from "../helpers/investment";
import { useOffering } from "../hooks/useOfferings";
import { useInvestmentJumpToStep } from "../hooks/use-investment-jump-to-step";
import { useInvestment } from "../hooks/useInvestments";
import Spinner from "./../components/spinner";

const Auth = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { investmentId, token } = useParams();
  const { data: user } = useCurrentUser();
  const toast = useToast();

  const [enableLoad, setEnableLoad] = useState(() => false);
  const { data: investment } = useInvestment(investmentId, {
    onError: () => {
      toast({ status: "error", description: "Sorry, the link has expired." });
      navigate(`/investor/link-expired`);
    },
  });

  const { data: offering } = useOffering(investment?.offering_id, {
    onError: () => {
      toast({ status: "error", description: "Sorry, the link has expired." });
      navigate(`/investor/link-expired`);
    },
  });

  const redirectOffering = useCallback(() => {
    const url = investmentContinueUrl(
      investment?.id,
      investment?.next,
      offering?.slug,
      investment?.status
    );
    if (!url) {
      if (investment?.status !== "PENDING") {
        toast({
          status: "info",
          description: "Your investment was already completed",
        });
      } else {
        toast({
          status: "warning",
          description: "Something went wrong. Please login to your vault.",
        });
      }
    }
    navigate(url ?? "/vault/dashboard");
  }, [investment?.next, investment?.id, offering?.slug, investment?.status]);

  const [, setInvestmentId] = useInvestmentJumpToStep(offering?.id);

  useEffect(() => {
    const dataCall = async () => {
      UserToken.setToken(token);
      dispatch({ type: "user", payload: user });
      setEnableLoad(true);
    };
    dataCall();
  }, [user]);

  useEffect(() => {
    if (enableLoad && investment && offering) {
      if (offeringIsCrowdfunded(offering)) {
        setInvestmentId(investment.id);
        redirectOffering();
      } else {
        // We still only use investment/offering redux in Reg D currently
        dispatch({ type: "investment", payload: investment });
        dispatch({ type: "offering", payload: offering });

        redirectOffering();
      }
    }
  }, [enableLoad, investment, offering]);

  return <Spinner show={true} />;
};

export default Auth;
