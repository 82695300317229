import { Box, Divider, Flex, Image, Progress, VStack } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Heading, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { NavLink, useLocation } from "react-router-dom";

const NR_OF_POSITIONS = 8;

export const WorkflowProgress = ({ offering, position }) => {
  const value = (position / NR_OF_POSITIONS) * 100; // calculates % value of investment progress

  return (
    <Flex w="full" align="center">
      <Progress variant="filled" w={`${value}%`} value={100} />
      <Progress variant="unfilled" w={`${100 - value}%`} value={0} />
    </Flex>
  );
};

export const IssuerInfo = ({ offering, isLargeScreen = false }) => {
  if (!offering) return null;
  const logo = offering?.issuer?.photo_url;
  const name = offering?.issuer?.name;

  return (
    <VStack gap="2.5" minW="full" mt={{ base: 4, lg: 0 }}>
      <Flex minW="full" direction={{ base: "row", lg: "column" }}>
        <Image
          mr={{ base: 2.5, lg: 0 }}
          mb={{ base: 0, lg: 2.5 }}
          w={{ base: "60px", lg: "100px" }}
          h={{ base: "60px", lg: "100px" }}
          src={logo}
          alt={name}
        />
        <Box>
          {!isLargeScreen && <Text mb="0">You're investing in</Text>}

          <Heading mb="0" textStyle="h1" fontWeight="bold">
            {offering?.issuer?.name}
          </Heading>
        </Box>
      </Flex>

      <Text mb="0" ml={{ base: 1, lg: 0 }} textStyle="body2" alignSelf="start">
        {offering?.name}
      </Text>
    </VStack>
  );
};

export const UserGreeting = () => {
  const user = useCurrentUser();
  if (user?.data?.entity_type === "ENTITY" && user?.data?.company) {
    return (
      <Heading fontWeight="bold" textStyle="h1" mt="4">
        {user?.data?.company}
      </Heading>
    );
  }

  return (
    <Heading fontWeight="bold" textStyle="h1" mt="4" mb="0">
      Hello{user?.data?.first_name ? `, ${user?.data?.first_name}` : "!"}
    </Heading>
  );
};

const NavItem = ({ onClick = () => {}, to, children, Icon, ...props }) => {
  const Action = to ? NavLink : Box;
  const { pathname } = useLocation();
  const isCurrentPage = to && pathname === to;

  return (
    <Box
      as={Action}
      onClick={onClick}
      to={to}
      cursor="pointer"
      w="full"
      _hover={{
        filter: "brightness(140%)",
      }}
      _active={{
        filter: "brightness(180%)",
      }}
      background="primary"
      pl={2}
      py={1}
      borderRadius="base"
      filter={isCurrentPage && "brightness(120%)"}
      {...props}
    >
      <Flex gap={2} my={2} opacity="90%">
        <Box as={Icon} opacity="90%" size="1.4em" />
        {children}
      </Flex>
    </Box>
  );
};

export const NavMenu = ({ logout, isAdditionalSignerPage }) => {
  const { data: me } = useCurrentUser();
  const location = useLocation();

  if (!me)
    return (
      <VStack mt={{ base: 0, lg: 4 }} alignItems="flex-start" minW="full">
        <Divider maxH={0} />
        <NavItem to="/support" Icon={Icon.HelpCircle}>
          Need Help?
        </NavItem>
        <NavItem
          to="/"
          state={{ redirectURL: location?.pathname }}
          Icon={Icon.LogIn}
        >
          Sign in
        </NavItem>
      </VStack>
    );

  return (
    <VStack mt={{ base: 0, lg: 4 }} alignItems="flex-start" minW="full">
      <Divider maxH={0} />
      {!isAdditionalSignerPage && (
        <>
          <NavItem to="/vault/dashboard" Icon={Icon.Briefcase}>
            Portfolio
          </NavItem>
          <NavItem to="/vault/profile" Icon={Icon.User}>
            Profile
          </NavItem>
        </>
      )}
      <NavItem to="/support" Icon={Icon.HelpCircle}>
        Need Help?
      </NavItem>
      <NavItem
        onClick={() => {
          logout({
            type: "success",
            message: "Logged out. Login again to access your Vault.",
          });
        }}
        Icon={Icon.LogOut}
      >
        Logout
      </NavItem>
    </VStack>
  );
};
