import { Box, Flex, VStack } from "@chakra-ui/react";
import { Text } from "@equidefi/ui";

const InvestmentDisplayItem = ({ label, value, helperText }) => {
  return (
    <Box
      w="full"
      py={4}
      borderBottom="1px"
      borderColor="gray.200"
      _last={{ border: "unset", pb: 0 }}
      key={label}
    >
      <Flex justify="space-between">
        {label && (
          <VStack align="start">
            <Text m="0">{label}</Text>
            {helperText && (
              <Text textStyle="context" m="0">
                {helperText}
              </Text>
            )}
          </VStack>
        )}
        <Text mb="0">{value ?? "N/A"}</Text>
      </Flex>
    </Box>
  );
};

export default InvestmentDisplayItem;
