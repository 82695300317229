import React, { ReactElement, useMemo } from "react";
import { Box, Center, Flex } from "@chakra-ui/react";
import { Paper } from "../Paper/Paper";
import { PaperProps } from "../Paper/PaperTheme";

interface Action extends ReactElement {
  props: {
    w?: any;
    h?: any;
    borderRadius?: any;
    borderLeftRadius?: any;
    bg?: any;
    color?: any;
    _hover?: any;
    _active?: any;
    transition?: any;
  };
}

interface CardListProps extends PaperProps {
  action?: Action;
  secondaryAction?: Action;
}

export const CardListItem = (props: CardListProps) => {
  const {
    action,
    secondaryAction,
    bg,
    background,
    children,
    onClick,
    ...rest
  } = props;

  const actionWithProps = useMemo(() => {
    if (React.isValidElement(action)) {
      return React.cloneElement(action, {
        w: "full",
        h: "full",
        borderRadius: "lg",
        borderLeftRadius: "0",
        bg: bg || background || "white",
        color: "gray.500",
        transition: "0.2s",
        _hover: {
          filter: "brightness(90%)",
        },
        _active: {
          filter: "brightness(50%)",
        },
        ...action.props,
      });
    }
    return action;
  }, [action]);

  const secondaryActionWithProps = useMemo(() => {
    if (React.isValidElement(secondaryAction)) {
      return React.cloneElement(secondaryAction, {
        w: "full",
        h: "full",
        borderRadius: "0",
        bg: bg || background || "white",
        color: "gray.500",
        transition: "0.2s",
        _hover: {
          filter: "brightness(90%)",
        },
        _active: {
          filter: "brightness(50%)",
        },
        ...secondaryAction.props,
      });
    }
    return secondaryAction;
  }, [secondaryAction]);

  return (
    <Paper
      bg={bg}
      _hover={
        onClick && {
          filter: "brightness(90%)",
        }
      }
      _active={
        onClick && {
          filter: "brightness(50%)",
        }
      }
      boxShadow="card"
      transition="0.2s"
      onClick={onClick}
      {...rest}
    >
      <Flex>
        <Box w="full" px="5" py="2.5">
          {children}
        </Box>
        {secondaryAction && (
          <Center w="65px">{secondaryActionWithProps}</Center>
        )}
        {action && <Center w="65px">{actionWithProps}</Center>}
      </Flex>
    </Paper>
  );
};
