import { Document, Page, Text, View } from "@react-pdf/renderer";
import React from "react";

import { styles } from "./PDF.styles";

export const PDFDocument = ({ children }) => {
  return <Document style={styles.document}>{children}</Document>;
};

export const PDFPage = ({ children }) => {
  return (
    <Page size="A4" wrap>
      <View style={[{ fontFamily: "Cerebri Sans" }, styles.wrapper]}>
        {children}
      </View>
    </Page>
  );
};

export const PDFSection = ({ title, children }) => {
  return (
    <View style={styles.section}>
      {title && <Text style={styles.sectionTitle}>{title}</Text>}
      {children}
    </View>
  );
};

export const Paragraph = (props) => {
  return <Text style={styles.paragraph} {...props} />;
};
