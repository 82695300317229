import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CONFIG } from "../constants/config";

/**
 *
 * @param {string} appEnv
 * @returns string
 */
function sentryEnv(appEnv) {
  switch (appEnv) {
    case "prod":
      return "production";
    case "dev":
      return "development";
    default:
      return appEnv ?? "production";
  }
}

/**
 *
 * If there is no environment variable to configure the DSN endpoint for Sentry,
 * the Sentry integration should not be initialized

 * @returns {boolean}
 */
export function sentryIsEnabled() {
  return process.env.NODE_ENV === "production" && !!CONFIG.SENTRY_DSN;
}

export function sentryStart() {
  Sentry.init({
    enabled: sentryIsEnabled(),
    environment: sentryEnv(CONFIG.ENV),
    dsn: CONFIG.SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
