import { useFormik } from "formik";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Button, Center, Text, useToast } from "@chakra-ui/react";
import { Heading, Password } from "@equidefi/ui";

import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { useSetNewPassword } from "../../hooks/useUsers";
import { resetFormSchema } from "../../../../issuer/src/constants/forms";
import LoginContainer from "./LoginContainer";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const params = useParams();
  const { redirectUrl = "/" } = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast({ status: "info" });
  useDocumentTitle("Reset Password");
  const setNewPassword = useSetNewPassword();

  const onSubmit = async ({ password }) => {
    try {
      await setNewPassword.mutateAsync({
        password,
        token: params.token,
        userId: params.userId,
      });

      toast({
        description: "Your password has been updated, please login to continue",
      });
      navigate(redirectUrl);
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      toast({
        status: "error",
        description:
          errorMessage ||
          "An error occured while trying to process your request.",
      });
    }
  };

  const { errors, touched, getFieldProps, handleSubmit, isValid } = useFormik({
    initialValues: initialValues,
    validationSchema: resetFormSchema,
    onSubmit,
    validateOnMount: true,
  });

  return (
    <LoginContainer>
      <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
        <Heading color="white" fontWeight="bold" m="0" textStyle="h3">
          Reset Password
        </Heading>
        <Text color="white" mb="5" textStyle="body2">
          Enter a secure password for your account
        </Text>
        <Password
          placeholder="Password"
          error={errors.password}
          isInvalid={errors.password && touched.password}
          h="12"
          mb="5"
          {...getFieldProps("password")}
        />

        <Password
          placeholder="Confirm Password"
          error={errors.confirmPassword}
          isInvalid={errors.confirmPassword && touched.confirmPassword}
          h="12"
          {...getFieldProps("confirmPassword")}
        />

        <Button
          isLoading={setNewPassword.isLoading}
          isDisabled={!isValid}
          type="submit"
          h="12"
          w="full"
        >
          Reset Password
        </Button>

        <Center mt="5">
          <Text
            fontSize="sm"
            color="gray.400"
            mb="0"
            as={Link}
            _hover={{ color: "gray.500" }}
            to={redirectUrl}
          >
            Remember your password? Log in
          </Text>
        </Center>
      </form>
    </LoginContainer>
  );
};

export default ResetPassword;
