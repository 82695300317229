import { VStack } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import InvestmentCard from "../../../components/investment/InvestmentCard";
import { useReinvestButton } from "../../../components/investment/ReinvestButton";
import { REINVEST_STATUSES } from "../../../constants/investment";
import { investmentContinueUrl } from "../../../helpers/investment";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useUserInvestments } from "../../../hooks/useInvestments";
import { WorkflowContext } from "../context";
import { WorkflowContainer } from "../WorkflowContainer";

const WorkflowChooseInvestment = (props) => {
  const navigate = useNavigate();
  const { offering } = useContext(WorkflowContext);
  const { data: investments, isFetchedAfterMount } = useUserInvestments(
    {
      offering_id: offering?.id,
    },
    { refetchOnMount: true }
  );
  const [cookies] = useCookies(["bcode"]);

  useDocumentTitle("Choose an Investment");

  const { onReinvest, isLoading } = useReinvestButton(offering?.slug);
  const handleReinvest = () => onReinvest(offering.id, cookies.bcode);

  const anyIncompleteInvestments = useMemo(() => {
    if (!isFetchedAfterMount) return true;
    return (
      investments?.length === 0 ||
      investments?.filter((i) => !REINVEST_STATUSES.includes(i.status))
        ?.length > 0
    );
  }, [investments, isFetchedAfterMount]);

  return (
    <WorkflowContainer
      title="Choose An Investment"
      buttonProps={{
        isDisabled: anyIncompleteInvestments,
        isLoading: isLoading,
      }}
      onContinue={handleReinvest}
      buttonLabel="Reinvest"
    >
      <VStack gap={4} mb={4} mt={4}>
        {investments?.map((investment) => (
          <InvestmentCard
            key={investment.id}
            investment={investment}
            cardAction={() => {
              const url = investmentContinueUrl(
                investment.id,
                investment.next,
                offering.slug,
                investment.status
              );

              navigate(url ?? `/vault/investment/${investment.id}`);
            }}
          />
        ))}
      </VStack>
    </WorkflowContainer>
  );
};

export default WorkflowChooseInvestment;
