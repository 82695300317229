import React from "react";

const Spinner = ({ show, text, children }) => {
  return (
    <>
      {show ? (
        <div className="text-center">
          <div className="spinner-border text-primary m-2">
            <span className="visually-hidden">{text || "Loading..."}</span>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Spinner;
