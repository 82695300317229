import * as FullStory from "@fullstory/browser";
import { once } from "lodash";

const FULL_STORY_ENABLED = process.env.NODE_ENV === "production";

export const initialize = once(() => {
  if (!FULL_STORY_ENABLED) return;

  FullStory.init({ orgId: "o-1FJ1ZH-na1" });
});

export const identify = once((user) => {
  if (!FULL_STORY_ENABLED) return;

  FullStory.identify(user.id, {
    displayName: `${user.first_name} ${user.last_name}`,
    email: user.email,
  });
});
