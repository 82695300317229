import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import { useUpdateCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Input } from "@equidefi/ui";

const addressSchema = Yup.object().shape({
  address_street_1: Yup.string().required("Please enter your street address"),
  address_street_2: Yup.string().nullable(),
  address_city: Yup.string().required("Please enter your city"),
  address_state: Yup.string().required("Please enter your state"),
  address_postal_code: Yup.string().required("Please enter your postal code"),
  address_country: Yup.string().required("Please enter your country"),
});

export const AddressModal = ({ modal, onSave, me }) => {
  const updateUser = useUpdateCurrentUser();
  const [spinner, setSpinner] = useState(false);
  const toast = useToast();

  const onSubmit = async (data) => {
    setSpinner(true);
    try {
      await updateUser.mutateAsync(data);
      toast({
        status: "success",
        description: `Your address has been updated successfully`,
      });
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: error.response.data.errors.join(". "),
      });
    }
    onSave();
    modal.onClose();
    setSpinner(false);
  };

  const initialValues = {
    address_street_1: me?.address_street_1 || "",
    address_street_2: me?.address_street_2 || "",
    address_city: me?.address_city || "",
    address_state: me?.address_state || "",
    address_postal_code: me?.address_postal_code || "",
    address_country: me?.address_country || "",
  };

  const { handleSubmit, errors, touched, getFieldProps } = useFormik({
    validationSchema: addressSchema,
    enableReinitialize: true,
    onSubmit,
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent mx="4">
        <ModalHeader>Edit your address</ModalHeader>
        <ModalCloseButton color="equidefi.red" />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Input
              label="Street Address"
              isInvalid={touched.address_street_1 && !!errors.address_street_1}
              isRequired
              error={errors.address_street_1}
              {...getFieldProps("address_street_1")}
            />
            <Input
              label="Street Address 2 (Unit #)"
              isInvalid={touched.address_street_2 && !!errors.address_street_2}
              error={errors.address_street_2}
              {...getFieldProps("address_street_2")}
            />
            <Input
              label="City"
              isInvalid={touched.address_city && !!errors.address_city}
              error={errors.address_city}
              isRequired
              {...getFieldProps("address_city")}
            />
            <Input
              label="State"
              isInvalid={touched.address_state && !!errors.address_state}
              error={errors.address_state}
              isRequired
              {...getFieldProps("address_state")}
            />
            <Input
              label="Postal Code"
              isInvalid={
                touched.address_postal_code && !!errors.address_postal_code
              }
              error={errors.address_postal_code}
              isRequired
              {...getFieldProps("address_postal_code")}
            />
            <Input
              label="Country"
              isInvalid={touched.address_country && !!errors.address_country}
              error={errors.address_country}
              isRequired
              {...getFieldProps("address_country")}
            />
            <Button my="2" type="submit" w="full" isLoading={spinner}>
              Save Changes
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
