import { ApiInstance } from "./api";

class IntercomClient {
  static async createTicket(data) {
    const response = await ApiInstance.post(`/v2/support/ticket/`, data);
    return response.data;
  }

  static async getTicketTypes() {
    const response = await ApiInstance.get(`/v2/support/ticket-types/`);
    return response.data;
  }
}

export default IntercomClient;
