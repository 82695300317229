import axios from "axios";

import UserToken from "./UserToken";

import type { AxiosInstance, AxiosRequestConfig } from "axios";

export class ApiBuilder {
  static buildClient(
    url: string,
    options: AxiosRequestConfig = {}
  ): AxiosInstance {
    const client = axios.create({ ...options, baseURL: url });

    client.interceptors.request.use(
      (config) => {
        const token = UserToken.token();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return client;
  }
}
