import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Button, Center, useToast } from "@chakra-ui/react";
import { Input, Password, Text } from "@equidefi/ui";

import { useLogin } from "@equidefi/portals/hooks/useAuthentication";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import LoginContainer from "./LoginContainer";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required."),
  password: Yup.string().required("Password is required."),
});

const initialValues = {
  email: "",
  password: "",
};

const Login = (props) => {
  useDocumentTitle("Investor Sign In");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [spinner, showSpinner] = useState(false);
  const [state, setState] = useState({});
  const location = useLocation();

  const { mutateAsync: triggerLogin } = useLogin({
    onSettled: () => showSpinner(false),
    onSuccess: () => {
      navigate("/mfa", { state: { ...state, ...location?.state } });
    },
    onError: (error) => {
      let errorMessage = "Incorrect email or password, please try again";
      if (error.response.status === 403) {
        errorMessage = "You are not allowed to access this portal";
      }
      toast({
        status: "error",
        description: errorMessage,
      });
    },
  });

  const login = async (form) => {
    showSpinner(true);

    const email = form.email.toLowerCase().trim();
    setState({ email, password: form.password });

    await triggerLogin({
      email,
      password: form.password,
      type: "investor",
    });

    dispatch({ type: "email", payload: email });
  };

  const { handleSubmit, errors, touched, getFieldProps, isValid } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: login,
    validateOnMount: true,
  });

  return (
    <LoginContainer>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Input
          error={errors.email}
          isInvalid={errors.email && touched.email}
          placeholder="Email Address"
          {...getFieldProps("email")}
          mb="5"
          h="12"
        />
        <Password
          error={errors.password}
          isInvalid={errors.password && touched.password}
          placeholder="Password"
          borderRadius={5}
          {...getFieldProps("password")}
          h="12"
        />
        <Button
          isLoading={spinner}
          isDisabled={!isValid}
          type="submit"
          h="12"
          w="full"
        >
          Continue
        </Button>

        <Center mt="5">
          <Text
            as={Link}
            _hover={{ color: "gray.500" }}
            color="gray.400"
            fontSize="sm"
            to={`/forgot`}
            textAlign="center"
          >
            Forgot password?
          </Text>
        </Center>
      </form>
    </LoginContainer>
  );
};

export default Login;
