import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useOpenOffering } from "../../hooks/useOfferings";

export default function WorkflowRedirect() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { data: offering } = useOpenOffering(slug);

  useEffect(() => {
    if (offering) {
      navigate(`/offerings/${slug}`);
    }
  }, [navigate, offering, slug]);

  return <></>;
}
