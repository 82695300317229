import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

export default function SupportPageSuccess() {
  return (
    <Box maxWidth="560px" w="full" p={6} borderRadius="lg" textAlign="center">
      <Flex
        justifyContent="center"
        alignItems="center"
        bg="green.100"
        w="80px"
        h="80px"
        borderRadius="full"
        mb={4}
        mx="auto"
      >
        <Box as={Icon.Check} color="green.500" boxSize={10} />
      </Flex>
      <Heading fontWeight="bold" mb={2}>
        Success
      </Heading>
      <Text mb={6}>
        Thank you for submitting your support ticket, someone from EquiDeFi will
        respond to you shortly!
      </Text>
    </Box>
  );
}
