import { Heading, Text } from "@equidefi/ui";
import { Flex, Image, Box } from "@chakra-ui/react";
import EquidefiLogo from "@equidefi/portals/images/equidefi-logo-black.png";

export default function OfferingError({ title, message }) {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w="full"
      minH="full"
      mt={{ base: 0, lg: 4 }}
    >
      <Box maxWidth="560px" w="full" p={{ base: 4, lg: 0 }} align="center">
        <Heading textStyle="h1" fontWeight="bold" mb="0" mt="150">
          {title}
        </Heading>
        <Text textStyle="body" mb="150">
          {message}
        </Text>
        <Image
          src={EquidefiLogo}
          alt="EquiDeFi"
          maxW="176px"
          alignSelf="center"
          opacity="30%"
        />
      </Box>
    </Flex>
  );
}
